import React, {memo} from 'react'
import Navigation from '../../Home/Navigation'
import Footer from '../../Home/Footer'

function HireExperts() {
  return (
    <>
      <Navigation />
      <div style={{ height: '100vh', padding: '100px 0 0 0', color: '#fff', fontSize: '100px' }}>HireExperts</div>
      <Footer />
    </>
  )
}
export default React.memo(HireExperts)