import React, { useEffect } from "react";
import "./PortfolioBoxNew.scss";

function PortfolioBoxNew(props) {
  return (
    <div className={`portfolio_wapper_new`}>
      <div class={`outbox ${props.side}`}>
        <div class="innerbox">
          {props.icon ?
            props?.icon
            :
            <img src={props.Image} alt={props.alt} title={props.cap} />
          }
          <p>{props.Name}</p>
        </div>
      </div>
    </div>
  );
}
export default React.memo(PortfolioBoxNew);
