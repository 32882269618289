import React, { memo } from "react";
import "./PortfolioBox.scss";

function PortfolioBox(props) {
  return (
    <div className={`portfolio_wapper`}>
      <div className="portfolio_box">
        <img
          width="650px"
          height="650px"
          src={props.Image}
          alt={props.alt}
          title={props.cap}
        />
        <div className="project_details">
          <h2>{props.Name}</h2>
          <h4>{props.Technology}</h4>
          <a target="_blank" href={props.Link}>
            Visit Site
          </a>
        </div>
      </div>
    </div>
  );
}
export default React.memo(PortfolioBox);
