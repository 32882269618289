import React, { lazy, Suspense } from 'react'
import Navigation from '../Home/Navigation'
// import 'boxicons';
import './index.scss';
import App from '../../Assets/Images/services/app.webp'
import Web from '../../Assets/Images/services/webdev.webp'
import Soft from '../../Assets/Images/services/software.webp'
import Erp from '../../Assets/Images/services/share.webp'
import Ecommerce from '../../Assets/Images/services/ecommerce.webp'
import Footer from '../Home/Footer';
import { Link } from 'react-router-dom'
import ReactImg from '../../Assets/Images/Technology/react.webp'
import AngularImg from '../../Assets/Images/Technology/angular.webp'
import NodeImg from '../../Assets/Images/Technology/node.webp'
import MobileImg from '../../Assets/Images/Technology/react.webp'
import MvcImg from '../../Assets/Images/Technology/mvc.webp'
import LaravelImg from '../../Assets/Images/Technology/laravel.webp'
import AndroidImg from '../../Assets/Images/Technology/android.webp'
import IosImg from '../../Assets/Images/Technology/ios.webp'
import Quality from '../../Assets/Images/services/quality.webp'
import parse from 'html-react-parser';

const Box = lazy(() => import('@mui/material/Box'));
const Tab = lazy(() => import('@mui/material/Tab'));
const Tabs = lazy(() => import('@mui/material/Tabs'));
const TabContext = lazy(() => import('@mui/lab/TabContext'));
const TabPanel = lazy(() => import('@mui/lab/TabPanel'));
const renderLoader = () => <p>Loading...</p>;

function BecomePartner() {
    const [value, setValue] = React.useState('1');
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <>
            <Navigation />
            <section className="services">
                <div className="container">
                    <div className="row text-center mb-5 mb-md-3">
                        <div className="col-lg-12">
                            <div className="common_heading text-center mt-5 service-head">
                                <h2 className='mb-0 mt-0 justify-content-start'>Our <span> Services</span></h2>
                                <p className='mt-2 mb-4 mb-md-0'>We offer a wide range of <a href="https://www.vidhaninfotech.com/viewservices/software-development">software development services</a> that provide industry specific solutions that align people, processes and systems to scale and grow your business to pro level.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-5 mb-md-0">

                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
export default React.memo(BecomePartner)