import React, { memo,  useState, Suspense, lazy } from 'react'
import { useParams } from 'react-router-dom';
import blogdata from './../blogdata'
import './../blog.scss'
import parse from 'html-react-parser';
import HowtoPick from './../../../Assets/Images/Blog/e_commerce_digital_marketing.webp'
import HowtoPick1 from './../../../Assets/Images/Blog/group_people_working_website_template.webp'
import HowtoPick2 from './../../../Assets/Images/Blog/programming_interface_software_programmer.webp'
import HowtoPick3 from './../../../Assets/Images/Blog/quality-assurance-in-app-development-think-about-business-perspective.webp'
import HowtoPick4 from './../../../Assets/Images/Blog/quality-issues-with-mobile-apps.webp'
import HowtoPick5 from './../../../Assets/Images/Blog/search_engine_optimization_ranking.webp'
import HowtoPick6 from './../../../Assets/Images/Blog/social_media_marketing.webp'
import HowtoPick7 from './../../../Assets/Images/Blog/web-template-website-design-concept.webp'
import HowtoPick8 from './../../../Assets/Images/Blog/close-up-businesspeople-working-with-documents.webp'
import HowtoPick9 from './../../../Assets/Images/Blog/web-design-blog.webp'
import HowtoPick10 from './../../../Assets/Images/Blog/marketing-manager.webp'
import HowtoPick11 from './../../../Assets/Images/Blog/coding-man.webp'
import HowtoPick12 from './../../../Assets/Images/Blog/communication-technology-selective-focus-science-internet-closeup.webp'
import HowtoPick13 from './../../../Assets/Images/Blog/develop-coding-web-design-coding-web-template.webp'
import HowtoPick14 from './../../../Assets/Images/Blog/commerce-push-ecommerce-store-cart-supermarket.webp'
import HowtoPick15 from './../../../Assets/Images/Blog/business-brainstorming-graph-chart-report-data-concept.webp'
import HowtoPick16 from './../../../Assets/Images/Blog/payment-customer-commerce-lifestyle-laptop.webp'
import HowtoPick17 from './../../../Assets/Images/Blog/interesting_facts_more_about_software_development.webp'

import shortid from 'shortid';

const BlogData = blogdata;
const renderLoader = () => <p>Loading...</p>;

function TopMorketingStratagey() {
    let { id } = useParams();
    const [activeBlog, setActiveBlog] = useState(id);
    const FoundBlog = BlogData.find(element => element.id == id);
    let topic = [
        {
            title: "What is quality assurance?",
            description: [
                { desc: "Quality assurance is the process of thoroughly testing your mobile app before submitting it to an app store to ensure that your users have the greatest possible experience." },
                { desc: "This confirms that the app is working properly. It also enables you to identify and address possible issues before the app (or changes to your published app) go live." },
                { desc: "It's a proactive approach that can help you save time, money, and stress." }
            ]
        }
    ]
    function Points(props) {
        return (
            <div className={`points_wrapper ${props.image ? 'imagebox' : ''} ${props.class}`}>
                <div className="point_content">
                    <div className="points_title">
                        <h2>{props.title}</h2>
                    </div>
                    <div className="points_description">
                        {
                            props.Description?.map((data, i) => (
                                <p key={shortid.generate}>{parse(`${data.desc}`)}</p>
                            ))
                        }
                    </div>
                </div>
                {
                    props.image &&
                    <div className="points_image">
                        <Suspense fallback={renderLoader()}>
                            <img src={props.image} alt={props.alt} title={props.cap} />
                        </Suspense>
                    </div>
                }
            </div>
        )
    }
    function Steps(props) {
        return (
            <div className={`points_wrapper ${props.image ? 'imagebox' : ''} ${props.class}`}>
                <div className="point_content">
                    <div className="points_title">
                        <h2>{props.title}</h2>
                    </div>
                    <div className="points_subtitle">
                        <h3>{props.subtitle}</h3>
                    </div>
                    <div className="steps_description">
                        <p className='step_desc'>{parse(`${props.description}`)}</p>
                        {
                            props.points?.map((data, i) => (
                                <p key={shortid.generate}>{parse(`${data.point}`)}</p>
                            ))
                        }
                    </div>
                </div>
                {
                    props.image &&
                    <div className="points_image">
                        <Suspense fallback={renderLoader()}>
                            <img src={props.image} alt={props.alt} title={props.cap} />
                        </Suspense>
                    </div>
                }
            </div>
        )
    }
    function Faq(props) {
        return (
            <div className="faq_question">
                <div className="faq_questions"><h3>{props.question}</h3></div>
                <div className="faq_answer"><p>{parse(`${props.answer}`)}</p></div>
            </div>
        )
    }
    return (
        <>
            <section className='viewblog'>
                <div className="container">
                    <div className="viewblog_header">
                        <p><span>Date : </span>{FoundBlog.date}</p>
                        <p><span>Category : </span>{FoundBlog.category}</p>
                    </div>
                    <div className="common_heading text-center mt-0">
                        <h1 className='mb-0 mt-4'>{FoundBlog.title}</h1>
                    </div>
                    <div className="common_content text-center">
                        <p className='mt-2 mb-5'>{FoundBlog.description}</p>
                    </div>
                    <div className="blogImage">
                        <Suspense fallback={renderLoader()}>
                            <img src={HowtoPick} alt={FoundBlog.alt} title={FoundBlog.cap} />
                        </Suspense>
                    </div>
                    <div className="common_content text-left mt-5">
                        {
                            FoundBlog.BlogPageData?.map((descData, i) => (
                                descData.description?.map((desc, i) => (
                                    <p className='mt-2 mb-3'>{desc.desc}</p>
                                ))
                            ))
                        }
                    </div>
                    {
                        FoundBlog.BlogPageData?.map((data, i) => (
                            data.topic?.map((innerData, i) => (
                                <Points
                                    title={innerData.title}
                                    Description={innerData.description}
                                    image={innerData.image}
                                    alt={innerData.alt}
                                    cap={innerData.cap}
                                    class={innerData.class}
                                />
                            ))
                        ))
                    }
                    {
                        FoundBlog.BlogPageData?.map((data, i) => (
                            data.topicPoints?.map((innerData, i) => (
                                <Steps
                                    title={innerData.title}
                                    description={innerData.desc}
                                    points={innerData.points}
                                    image={innerData.image}
                                    alt={innerData.alt}
                                    cap={innerData.cap}
                                    class={innerData.class}
                                    subtitle={innerData.subtitle}

                                />
                            ))
                        ))
                    }
                    {
                        FoundBlog.BlogPageData?.map((data, i) => (
                            data.topic1?.map((innerData, i) => (
                                <Points
                                    title={innerData.title}
                                    Description={innerData.description}
                                    image={innerData.image}
                                    alt={innerData.alt}
                                    cap={innerData.cap}
                                    class={innerData.class}
                                />
                            ))
                        ))
                    }
                    {
                        FoundBlog.BlogPageData?.map((data, i) => (
                            data.topicPoints1?.map((innerData, i) => (
                                <Steps
                                    title={innerData.title}
                                    description={innerData.desc}
                                    points={innerData.points}
                                    image={innerData.image}
                                    alt={innerData.alt}
                                    cap={innerData.cap}
                                    class={innerData.class}
                                />
                            ))
                        ))
                    }
                    <div className="FAQ">
                        {
                            FoundBlog.BlogPageData?.map((data, i) => (
                                data.faq?.map((innerData, i) => (
                                    <>
                                        <div className="faq_title">
                                            <h2>{innerData.title}</h2>
                                        </div>
                                        {
                                            innerData.questions?.map((questionData, i) => (
                                                <Faq
                                                    question={questionData.question}
                                                    answer={questionData.answer}
                                                />
                                            ))
                                        }
                                    </>
                                ))
                            ))
                        }
                    </div>
                </div>
            </section>
        </>
    )
}
export default React.memo(TopMorketingStratagey)