import React, { useEffect, useState, lazy, useRef } from "react";
import { useForm } from "react-hook-form";
import { NavLink, useLocation } from "react-router-dom";
import Meta from "../../Components/Meta";
import Logoblue from "../../Assets/Images/logo-black-white.png";
const Swal = lazy(() => import("sweetalert2"));
// function ScrollToTopOnMount() {
//     useEffect(() => {
//       window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
//     }, );
//     return null;
//   }

function Navigation() {
  let menuData = [
    {
      mId: 1,
      menu: "Home",
      to: "/",
    },
    {
      mId: 2,
      menu: "About",
      to: "/about",
      // submenus: [
      //     {
      //         id: 1,
      //         name: "About Us",
      //         icon: 'buildings',
      //         to: "/about/about-us",
      //     },
      //     {
      //         id: 2,
      //         name: "Locations",
      //         icon: 'map-alt',
      //         to: "/about/locations",
      //     },
      //     {
      //         id: 3,
      //         name: "Startup Approach",
      //         icon: 'rocket',
      //         to: "/about/startup-approach",
      //     },
      //     {
      //         id: 4,
      //         name: "Engagement Models",
      //         icon: 'shape-circle',
      //         to: "/about/engagement-models",
      //     },
      // ]
    },
    {
      mId: 3,
      menu: "Services",
      to: "/services",
      // submenus: [
      //     {
      //         id: "1",
      //         name: "Full Stack",
      //         icon: "layer",
      //         to: "/full-stack",
      //         innerMenus: [
      //             {
      //                 title: "Full Stack Development",
      //                 items: [
      //                     {
      //                         name: "Mean Stack Development",
      //                         to: "/mean-stack-development",
      //                     },
      //                     {
      //                         name: "Mern Stack Development",
      //                         to: "/mern-stack-development",
      //                     },
      //                     {
      //                         name: "Angular js Development",
      //                         to: "/angular-js-development",
      //                     },
      //                     {
      //                         name: "React js Development",
      //                         to: "/react-js-development",
      //                     },
      //                     {
      //                         name: "Node js Development",
      //                         to: "/node-js-development",
      //                     },
      //                     {
      //                         name: "Ruby on rails Development",
      //                         to: "/ruby-on-rails-development",
      //                     },
      //                     {
      //                         name: "Python Django Development",
      //                         to: "/python-django-development",
      //                     },
      //                     {
      //                         name: "ASP .Net Core Development",
      //                         to: "/asp.net-core-development",
      //                     },
      //                     {
      //                         name: "AI & BOT Development",
      //                         to: "/ai-bot-development",
      //                     },
      //                 ]
      //             },
      //             {
      //                 title: "Cloud Services",
      //                 items: [
      //                     {
      //                         name: "Cloud Migration Services",
      //                         to: "/cloud-migration-services",
      //                     },
      //                     {
      //                         name: "AWS Data Migration",
      //                         to: "/aws-data-migration",
      //                     },
      //                     {
      //                         name: "AWS Deployment",
      //                         to: "/aws-development",
      //                     },
      //                     {
      //                         name: "AWS Cloud Consulting",
      //                         to: "/aws-cloud-consulting",
      //                     },
      //                     {
      //                         name: "Cloud Application Services",
      //                         to: "/cloud-application-services",
      //                     }
      //                 ],
      //             },
      //             {
      //                 title: "FULL STACK SERVICES",
      //                 description: [
      //                     {
      //                         desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
      //                         link: "/common",
      //                     },
      //                 ],

      //             },
      //         ]
      //     },
      //     {
      //         id: "2",
      //         name: "Web Development",
      //         icon: "cog",
      //         to: "/web-development",
      //         innerMenus: [
      //             {
      //                 title: "Custom PHP Development",
      //                 items: [
      //                     {
      //                         name: "CakePHP Development",
      //                         to: "/cake-php-development",
      //                     },
      //                     {
      //                         name: "Zend Development",
      //                         to: "/zend-development",
      //                     },
      //                     {
      //                         name: "CodeIgniter Development",
      //                         to: "/codeIgniter-development",
      //                     },
      //                     {
      //                         name: "Open Source Development",
      //                         to: "/open-source-development",
      //                     },
      //                     {
      //                         name: "Yii Development",
      //                         to: "/yii-development",
      //                     }
      //                 ]
      //             },
      //             {
      //                 title: "CMS Development",
      //                 items: [
      //                     {
      //                         name: "Sitecore Development",
      //                         to: "/sitecore-development",
      //                     },
      //                     {
      //                         name: "Wordpress Development",
      //                         to: "/wordpress-development",
      //                     },
      //                     {
      //                         name: "Drupal Development",
      //                         to: "/drupal-development",
      //                     },
      //                     {
      //                         name: "Joomla Development",
      //                         to: "/joomla-development",
      //                     },
      //                     {
      //                         name: "Umbraco Development",
      //                         to: "/umbraco-development",
      //                     }
      //                 ],
      //             },
      //             {
      //                 title: "Microsoft Services",
      //                 items: [
      //                     {
      //                         name: "ASP.Net Development",
      //                         to: "/asp.net-development",
      //                     },
      //                     {
      //                         name: "Database Technologies",
      //                         to: "/database-technologies",
      //                     },
      //                     {
      //                         name: "ASP.Net Shopping Cart Solution",
      //                         to: "/asp.net-shopping-cart-solution",
      //                     },
      //                     {
      //                         name: "Azure Development",
      //                         to: "/azure-development",
      //                     },
      //                     {
      //                         name: "SAAS Application Development",
      //                         to: "/saas-application-development",
      //                     },
      //                     {
      //                         name: "SQL Reporting Services",
      //                         to: "/sql-reporting-services",
      //                     },
      //                     {
      //                         name: "Asp.net MVC Development",
      //                         to: "/asp.net-mvc-development",
      //                     },
      //                     {
      //                         name: "C# Development",
      //                         to: "/c#-development",
      //                     },
      //                     {
      //                         name: "Sharepoint Development",
      //                         to: "/sharepoint-development",
      //                     },
      //                     {
      //                         name: "Custom Application Development",
      //                         to: "/custom-application-development",
      //                     },
      //                     {
      //                         name: "MS Dynamics CRM",
      //                         to: "/ms-dynamics-crm",
      //                     },
      //                     {
      //                         name: "Web Maintenance",
      //                         to: "/web-maintenance",
      //                     },
      //                 ],
      //             },
      //         ]
      //     },
      //     {
      //         id: "3",
      //         name: "IoT",
      //         icon: "sitemap",
      //         to: "/iot",
      //         innerMenus: [
      //             {
      //                 title: "Internet of Things",
      //                 items: [
      //                     {
      //                         name: "Machine to Machine Communication",
      //                         to: "/machine-to-machine-communication",
      //                     },
      //                     {
      //                         name: "Human to Machine Communication",
      //                         to: "/human-to-machine-communication",
      //                     },
      //                     {
      //                         name: "Machine to Human Communication",
      //                         to: "/machine-to-human-communication",
      //                     },
      //                     {
      //                         name: "IoT for Animals",
      //                         to: "/iot-for-animals",
      //                     },

      //                 ]
      //             },
      //             {
      //                 title: "HUMAN TO MACHINE COMMUNICATION",
      //                 description: [
      //                     {
      //                         desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
      //                         link: "/common",
      //                     },
      //                 ],

      //             },
      //         ]
      //     },
      //     {
      //         id: "4",
      //         name: "Mobile App",
      //         icon: "mobile-alt",
      //         to: "/mobile-app",
      //         innerMenus: [
      //             {
      //                 title: "Mobile App Development",
      //                 items: [
      //                     {
      //                         name: "React Native App Development",
      //                         to: "/react-native-app-development",
      //                     },
      //                     {
      //                         name: "iPhone Mobile App Development",
      //                         to: "/iphone-mobile-app-development",
      //                     },
      //                     {
      //                         name: "Android Mobile App Development",
      //                         to: "/android-mobile-app-development",
      //                     },
      //                     {
      //                         name: "Cross Platform Mobile App Development",
      //                         to: "/cross-platform-mobile-app-development",
      //                     },
      //                     {
      //                         name: "Enterprise Mobility Solutions",
      //                         to: "/enterprise-mobility-solutions",
      //                     },
      //                     {
      //                         name: "Tablet Application Development",
      //                         to: "/tablet-application-development",
      //                     },
      //                     {
      //                         name: "Wearable Device Application Development",
      //                         to: "/wearable-device-application-development",
      //                     },
      //                     {
      //                         name: "Appcelerator Titanium",
      //                         to: "/appcelerator-titanium",
      //                     },
      //                     {
      //                         name: "Phonegap Development Services",
      //                         to: "/phonegap-development-services",
      //                     },
      //                     {
      //                         name: "Ipad Application Development",
      //                         to: "/ipad-application-development",
      //                     },
      //                     {
      //                         name: "Mobile Commerce Development",
      //                         to: "/mobile-commerce-development",
      //                     },
      //                 ]
      //             },
      //             {
      //                 title: "MOBILE APP DEVELOPMENT",
      //                 description: [
      //                     {
      //                         desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
      //                         link: "/common",
      //                     },
      //                 ],

      //             },
      //         ]
      //     },
      //     {
      //         id: "5",
      //         name: "E-Commerce",
      //         icon: "cart",
      //         to: "/e-commerece",
      //         innerMenus: [
      //             {
      //                 title: "E-Commerce Platforms",
      //                 items: [
      //                     {
      //                         name: "Magento Development",
      //                         to: "/magento-development",
      //                     },
      //                     {
      //                         name: "WooCommerce Development",
      //                         to: "/wooCommerce-development",
      //                     },
      //                     {
      //                         name: "Shopify Development",
      //                         to: "/shopify-development",
      //                     },
      //                     {
      //                         name: "Zen Cart Development",
      //                         to: "/zen-cart-development",
      //                     },
      //                     {
      //                         name: "OpenCart Development",
      //                         to: "/openCart-development",
      //                     },
      //                     {
      //                         name: "osCommerce Development",
      //                         to: "/oscommerce-development",
      //                     },
      //                     {
      //                         name: "BigCommerce Development",
      //                         to: "/bigcommerce-development",
      //                     },
      //                     {
      //                         name: "NopCommerce Development",
      //                         to: "/nopcommerce-development",
      //                     },
      //                     {
      //                         name: "Joomla E-Commerce",
      //                         to: "/joomla-e-commerce",
      //                     },
      //                 ]
      //             },
      //             {
      //                 title: "Third Party Integration",
      //                 items: [
      //                     {
      //                         name: "XERO",
      //                         to: "/xero",
      //                     },
      //                     {
      //                         name: "ERP",
      //                         to: "/erp",
      //                     },
      //                     {
      //                         name: "Payment Integration",
      //                         to: "/payment-integration",
      //                     },
      //                     {
      //                         name: "E-Bay Integration",
      //                         to: "/e-bay-integration",
      //                     },
      //                     {
      //                         name: "Amazon Integration",
      //                         to: "/amazon-integration",
      //                     },
      //                     {
      //                         name: "AWS Web Hosting",
      //                         to: "/aws-web-hosting",
      //                     },

      //                 ]
      //             },
      //             {
      //                 title: "E-Commerce Development",
      //                 items: [
      //                     {
      //                         name: "E-Commerce Development",
      //                         to: "/e-commerce-development",
      //                     },
      //                     {
      //                         name: "B2B Website Development",
      //                         to: "/b2b-website-development",
      //                     },
      //                     {
      //                         name: "Portal Development",
      //                         to: "/portal-development",
      //                     },
      //                 ]
      //             }
      //         ]
      //     },
      //     {
      //         id: "6",
      //         name: "Web Design",
      //         icon: "devices",
      //         to: "/web-design",
      //         innerMenus: [
      //             {
      //                 title: "UI/UX Design",
      //                 items: [
      //                     {
      //                         name: "Custom Website Design",
      //                         to: "/custom-website-design",
      //                     },
      //                     {
      //                         name: "Mobile UI Design",
      //                         to: "/mobile-ui-design",
      //                     },
      //                     {
      //                         name: "Responsive Web Design",
      //                         to: "/responsive-web-design",
      //                     },
      //                     {
      //                         name: "PSD to Magento",
      //                         to: "/psd-to-magento",
      //                     },
      //                     {
      //                         name: "PSD to Joomla",
      //                         to: "/psd-to-joomla",
      //                     },
      //                     {
      //                         name: "PSD to WordPress",
      //                         to: "/psd-to-wordPress",
      //                     },
      //                     {
      //                         name: "PSD to Drupal",
      //                         to: "/psd-to-drupal",
      //                     },
      //                     {
      //                         name: "PSD to ASP.Net",
      //                         to: "/psd-to-asp.net",
      //                     },
      //                 ]
      //             },
      //             {
      //                 title: "WEBSITE DESIGN SERVICES",
      //                 description: [
      //                     {
      //                         desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
      //                         link: "/common",
      //                     },
      //                 ],

      //             },
      //         ]
      //     },
      //     {
      //         id: "7",
      //         name: "Internet Marketing",
      //         icon: "station",
      //         to: "/internet-marketing",
      //         innerMenus: [
      //             {
      //                 title: "Internet Marketing",
      //                 items: [
      //                     {
      //                         name: "Search Engine Optimization",
      //                         to: "/search-engine-optimization",
      //                     },
      //                     {
      //                         name: "PPC Management",
      //                         to: "/ppc-management",
      //                     },
      //                     {
      //                         name: "Social Media Marketing",
      //                         to: "/social-media-marketing",
      //                     },
      //                     {
      //                         name: "E-mail Marketing",
      //                         to: "/e-mail-marketing",
      //                     },
      //                     {
      //                         name: "Content Marketing",
      //                         to: "/content-marketing",
      //                     },

      //                 ]
      //             },
      //             {
      //                 title: "INTERNET MARKETING",
      //                 description: [
      //                     {
      //                         desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
      //                         link: "/common",
      //                     },
      //                 ],

      //             },
      //         ]
      //     },
      //     {
      //         id: "8",
      //         name: "Hire-Experts",
      //         icon: "search",
      //         to: "/hire-experts",
      //         innerMenus: [
      //             {
      //                 title: "Hire Experts",
      //                 items: [
      //                     {
      //                         name: "Hire Expert Magento Developers",
      //                         to: "/hire-expert-magento-developers",

      //                     },
      //                     {
      //                         name: "Hire Expert CMS Developers",
      //                         to: "/hire-expert-cms-developers",
      //                     },
      //                     {
      //                         name: "Hire Mobile Developers",
      //                         to: "/hire-mobile-developers",
      //                     },
      //                     {
      //                         name: "Hire Sitecore Certified Developers",
      //                         to: "/hire-sitecore-certified-developers",
      //                     },
      //                     {
      //                         name: "Hire Microsoft Certified Developers",
      //                         to: "/hire-microsoft-certified-developers",
      //                     },
      //                     {
      //                         name: "Hire SAAS Developers",
      //                         to: "/hire-saas-developers",
      //                     },
      //                     {
      //                         name: "Hire PHP Developers",
      //                         to: "/hire-php-developers",
      //                     },

      //                 ]
      //             },
      //             {
      //                 title: "HIRE EXPERTS",
      //                 description: [
      //                     {
      //                         desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
      //                         link: "/common",
      //                     },
      //                 ],
      //             },
      //         ]
      //     },
      // ]
    },
    {
      mId: 4,
      menu: "Portfolio",
      to: "/portfolio",
      // submenus: [
      //     {
      //         id: "1",
      //         name: "Portfolio",
      //         icon: "layer",
      //     }
      // ]
    },
    {
      mId: 5,
      menu: "Blog",
      to: "/blog",
    },
    {
      mId: 6,
      menu: "Career",
      to: "/career",
    },
    // {
    //     mId: 7,
    //     menu: "Become Partner",
    //     to: "/become-partner",
    // },
  ];

  const [isNav, setIsNav] = useState(false);

  let activeClassName = "active";
  const location = useLocation();
  const { pathname } = location;
  const CurrentLocation = pathname.split("/");
  const [isShown, setIsShown] = useState(false);
  const [subOpen, setSubOpen] = useState(false);
  const [subOpenId, setSubOpenId] = useState();
  const [menuId, setMenuId] = useState(0);
  const [view, setView] = useState(false);
  const handleHover = (e) => {
    // setSubOpen(subOpen ? false : true);
    setSubOpenId(e);
  };
  const handleHoverLeave = (e) => {
    setSubOpen(subOpen ? false : true);
  };
  const handleDropdown = (id) => {
    setSubOpenId();
    setIsShown(true);
    setMenuId(id);
  };

  useEffect(() => {
    const toRef = setTimeout(() => {
      setView(true);
      clearTimeout(toRef);
    }, 3000);
    setNum(randomNumberInRange(1, 9));
    setNum1(randomNumberInRange(1, 9));
  }, [view]);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [alert, setAlert] = useState("");
  const [loading, setLoading] = useState(false);
  const [verified, setVerified] = useState(false);
  const [setError] = useState("");
  const [reError, setReError] = useState(false);
  const [num, setNum] = useState(0);
  const [num1, setNum1] = useState(0);
  const [sum, setSum] = useState(0);
  const inputRef = useRef(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (verified) {
      const toRef = setTimeout(() => {
        setVerified(false);
        setNum(randomNumberInRange(1, 9));
        setNum1(randomNumberInRange(1, 9));
        inputRef.current.value = "";
        clearTimeout(toRef);
      }, 30000);
    }
  }, [verified]);
  const handleVerify = () => {
    if (num + num1 === parseInt(sum)) {
      setVerified(true);
      setSum(0);
      setReError(false);
      inputRef.current.value = "";
    } else {
      setSum(0);
      setReError(true);
      inputRef.current.value = "";
    }
  };

  function randomNumberInRange(min, max) {
    // 👇️ get number between min (inclusive) and max (inclusive)
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  const handleData = (e) => {
    e.preventDefault();
    setLoading(true);
    let fd = new FormData();
    fd.append("name", name);
    fd.append("email", email);
    fd.append("subject", subject);
    fd.append("message", message);

    fetch(`http://viapi.vidhaninfotech.com/api/contact-us-store`, {
      method: "POST",
      body: fd,
      headers: {
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((Contact) => {
        if (Contact.success === true) {
          setLoading(false);
          Swal.fire({
            icon: "success",
            // title: 'Notification',
            text: "We received your request, We will respond soon!",
            type: "success",
          });
          setError("");
          setName("");
          setEmail("");
          setSubject("");
          setMessage("");
          setAlert("");
        } else {
          setLoading(false);
          setError(Contact.message);
        }
      });
  };

  const [isOpen, setOpen] = useState(false);
  const OpenModal = () => {
    setOpen(true);
  };
  const CloseModal = () => {
    setOpen(false);
  };
  return (
    <React.Fragment>
      <Meta />
      <div className="navigation_wrapper">
        <section className="navigation">
          <div className="navigation__logo">
            {/* {
                            view && */}
            <NavLink to="/">
              <img
                src={Logoblue}
                width={localStorage.getItem("windowWidth") < 500 ? "80" : "100"}
                height={localStorage.getItem("windowWidth") < 500 ? "57" : "71"}
                alt="Vidhan Infotech Pvt. Ltd. Blue Logo"
                title="Vidhan Infotech Pvt. Ltd. Blue Logo"
                tags="vidhan infotech pvt ltd,vidhan infotech in ahmedabad,software development company in gujarat,custom software development company in ahmedabad,website development company in ahmedabad,website development in ahmedabad, best website designer in ahmedabad"
              />
            </NavLink>
            {/* } */}
          </div>
          <div
            className={`navigation__links ${
              isNav ? "mobile_navigation active" : ""
            }`}
          >
            <ul className="nav">
              {menuData.map((menuitem, i) => (
                <li
                  key={menuitem.mId}
                  onMouseEnter={() => handleDropdown(menuitem.mId)}
                  onMouseLeave={() => setIsShown(false)}
                >
                  <NavLink
                    to={menuitem.to}
                    id={menuitem.mId}
                    className={`${
                      CurrentLocation[1] === menuitem.to ? "active" : ""
                    } ${({ isActive }) =>
                      isActive ? activeClassName : undefined}`}
                  >
                    {" "}
                    {menuitem.menu}{" "}
                  </NavLink>
                  {menuitem.submenus && menuitem.mId === menuId ? (
                    <div className={`submenu ${isShown ? "active" : ""}`}>
                      <div className="submenu_menu">
                        <div style={{ display: "flex" }}>
                          <nav>
                            {menuitem.submenus?.map((menu, i) =>
                              menuitem.mId === menuId ? (
                                <NavLink
                                  key={menu.id}
                                  id={menu.id}
                                  to={menu.to}
                                  onMouseEnter={() => handleHover(menu.id)}
                                  onMouseLeave={() => handleHoverLeave()}
                                  className={`${
                                    subOpenId === menu.id ? "activesubmenu" : ""
                                  }`}
                                >
                                  <box-icon name={menu.icon}></box-icon>
                                  {menu.name}
                                </NavLink>
                              ) : null
                            )}
                          </nav>
                        </div>
                      </div>
                      {menuitem.submenus?.map((submenu) =>
                        submenu.id === subOpenId ? (
                          <div className="inner_sub_wrapper">
                            {submenu.innerMenus?.map((innermenu) => (
                              <div className="inner_sub_menu">
                                <div className="inner_sub_menu_title">
                                  {innermenu.title}
                                </div>
                                {innermenu.items ? (
                                  <div className="inner_sub_menu_content">
                                    {innermenu.items?.map((item) => (
                                      <NavLink to={`/viewservices${item.to}`}>
                                        {item.name}
                                      </NavLink>
                                    ))}
                                  </div>
                                ) : innermenu.description ? (
                                  <div className="inner_sub_menu_description">
                                    {innermenu.description?.map((item) => (
                                      <>
                                        <span>{item.desc}</span>
                                        <NavLink to={item.link}>
                                          Explore More
                                        </NavLink>
                                      </>
                                    ))}
                                  </div>
                                ) : null}
                              </div>
                            ))}
                          </div>
                        ) : (
                          ""
                        )
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </li>
              ))}
              <li className="mobile_action">
                <button onClick={() => OpenModal()}>FREE ESTIMATE</button>
              </li>
            </ul>
          </div>

          {
            <div className="navigation__social__media">
              <a
                href="https://www.facebook.com/vidhan.infotech.1/"
                target="_blank"
                className="social__link facebook"
                rel="noreferrer"
              >
                <box-icon type="logo" name="facebook"></box-icon>
              </a>
              <a
                href="https://www.instagram.com/vidhaninfotech/"
                target="_blank"
                className="social__link insta"
                rel="noreferrer"
              >
                <box-icon type="logo" name="instagram"></box-icon>
              </a>
              <a
                href="https://www.linkedin.com/company/vidhan-infotech-private-limited/"
                target="_blank"
                className="social__link linkedin"
                rel="noreferrer"
              >
                <box-icon type="logo" name="linkedin"></box-icon>
              </a>
            </div>
          }
          <div className="navigation_action">
            <button onClick={() => OpenModal()}>FREE ESTIMATE</button>
          </div>
          {isOpen ? (
            <div className="modal">
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">GET A FREE QUOTE</h5>
                    <button
                      type="button"
                      className="closebtn"
                      onClick={CloseModal}
                    >
                      <box-icon name="x"></box-icon>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form
                      onSubmit={handleSubmit(handleData)}
                      className="getintouchform"
                    >
                      <div className="row">
                        <div className="col-md-12">
                          <input
                            type="text"
                            value={name}
                            placeholder="Your Name"
                            required
                            onChange={(e) => setName(e.target.value)}
                          />
                        </div>
                        <div className="col-md-12">
                          <input
                            type="email"
                            value={email}
                            placeholder="Your Email"
                            {...register("email", {
                              required: {
                                value: true,
                                message: "This field is required",
                              },
                              pattern: {
                                value:
                                  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: "Enter Valid Email",
                              },
                            })}
                            required
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                        <div className="col-md-12">
                          <div className="alertmessage">
                            {errors.email && (
                              <span>{errors.email.message}</span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-12">
                          <input
                            type="text"
                            value={subject}
                            placeholder="Subject"
                            required
                            onChange={(e) => setSubject(e.target.value)}
                          />
                        </div>
                        <div className="col-md-12">
                          <input
                            type="text"
                            value={message}
                            placeholder="Message"
                            required
                            onChange={(e) => setMessage(e.target.value)}
                          />
                        </div>
                        <div className="col-md-12">
                          <div className="alertmessage">{alert}</div>
                        </div>
                        <div className="col-md-12">
                          <div className="recaptcha_container">
                            <span>
                              {num && num} + {num1 && num1} ={" "}
                            </span>
                            <input
                              type="text"
                              ref={inputRef}
                              name="inputValue"
                              disabled={verified ? true : false}
                              onChange={(e) => setSum(e.target.value)}
                              maxLength="3"
                              placeholder="0"
                            />
                            <button
                              type="button"
                              onClick={() => handleVerify()}
                              disabled={sum !== 0 ? false : true}
                            >
                              Verify
                            </button>
                          </div>
                          <span className="error_msg">
                            {reError && "You have entered wrong value"}
                          </span>
                        </div>

                        <div className="modal-footer col-md-12 mt-3 justify-content-center">
                          <input
                            type="submit"
                            className="closebtn"
                            disabled={verified ? false : true}
                            value={loading ? "Loading..." : "SEND A MESSAGE"}
                          />
                          <p className="mt-3">
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href="https://wa.me/8780838782"
                            >
                              Directly Connect To What's Up :
                              <b> +91 8780 838 782</b>
                            </a>
                          </p>
                        </div>
                      </div>
                    </form>
                  </div>
                  {/* <div className="modal-footer">
                                                <button type="button" className="closebtn" onClick={CloseModal}>Close</button>
                                            </div> */}
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          <div
            onClick={() => setIsNav(!isNav)}
            className={`toggle_menu ${isNav ? "active" : ""}`}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
}
export default React.memo(Navigation);
