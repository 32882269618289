import React, { useEffect, useState, lazy } from "react";
import NavigationBar from "../Shared/NavigationBar";
import NewFooter from "../Shared/NewFooter";
import "./Career.scss";
// import 'boxicons';
import { useForm } from "react-hook-form";
import parse from "html-react-parser";
import experience from "../../Assets/Red_Theme/experience.png";
import description from "../../Assets/Red_Theme/description.png";

const Swal = lazy(() => import("sweetalert2"));
// const ReCaptchaV2 = lazy(() => import('react-google-recaptcha'));

function Career() {
  useEffect(() => {
    // Getjobs();
    // Getjoblist();
  }, []);
  // upload Image

  const [apply, setApply] = useState(false);
  // const [joblist, setJoblist] = useState([]);
  const [resume, setResume] = useState();
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState("");
  const [verified] = useState(false);
  // const [joblistData, setJoblistData] = useState([]);
  const [error, setError] = useState("");

  // const handleToken = (token) => {
  //     setVerified(true);
  // }

  const uploadPicture = (e) => {
    // picturePreview: URL.createObjectURL(e.target.files[0]),
    setResume(e.target.files[0]);
  };
  // Get Job List

  // const Getjobs = () => {
  //     fetch('http://viapi.barbersapp.in/api/get-jobs', {
  //         headers: {
  //             'Content-Type': 'application/json',
  //             'Accept': 'application/json'
  //         }
  //     })
  //         .then((response) => response.json())
  //         .then(Alljobs => {
  //             const jobdata = Alljobs.data.map((jobs, i) => {
  //                 return {
  //                     id: jobs.id,
  //                     jobname: jobs.title
  //                 };
  //             });
  //             setJoblist(jobdata);
  //         });
  // }

  const JobListData = [
    {
      id: "1",
      sequence_id: "1",
      title: "Asp .Net Mvc Developer",
      image: "asp.webp",
      experience: "1 to 6 years",
      description:
        "We are looking for an ASP.NET MVC developer that will be able to develop and support existing applications. The main scope of work will include everything from back-end to client-side code, using optimal and efficient technologies, frameworks, and patterns. Your primary responsibilities will be to design and develop these applications. Therefore, it’s essential that you are skilled at problem solving, solution design, and high-quality coding.",
      skills:
        "<ul><li>.NET, MVC+ , SQL, (Angular and .Net core if anyone know then good)</li><li>client communication with fluent English.</li></ul>",
      other_skills:
        "<ul><li>Angular or React Experience is Advantage</li></ul>",
      responsibilities:
        "<ul><li>Delivering a high-quality application</li><li>Integrating with front-end developers ( if applicable )</li><li>Ensuring a required level of performance</li><li>Writing test-backed server-side code</li><li>Analyzing requirements and designing new functionality</li><li>Supporting and fixing existing functionality</li></ul>",
    },
    // { "id": "2", "sequence_id": "2", "title": "Laravel Developer", "image": "laravel.webp", "experience": "2 to 4 years", "description": "We are looking for a Laravel Developer responsible for managing back-end services and the interchange of data between the server and the users. Your primary focus will be the development of all server-side logic, definition and maintenance of the central database, and ensuring high performance and responsiveness to requests from the front-end. You will also be responsible for integrating the front-end elements built by your co-workers into the application. Therefore, a basic understanding of front-end technologies is necessary as well.", "skills": "<ul><li>Demonstrable experience with PHP including OOP, security, refactoring & design patterns.</li><li>Excellent knowledge of Laravel framework and experience building factories/facades/libraries using abstract classes, interfaces, traits.</li><li>Hands on experience in RESTful API design and development including implementation of oAuth2.0 for authentication and authorization.</li><li>Demonstrable experience with PHP including OOP, security, refactoring & design patterns.</li><li>Good core knowledge of HTML5, CSS3, jQuery and Bootstrap framework.</li><li>Strong problem solving and analytical skills.</li></ul>", "other_skills": null, "responsibilities": "<ul><li>Write clean, secure, test driven, easily maintainable and modular codes.</li><li>Database designing and maintenance.</li><li>Build reusable code and libraries for future use.</li><li>Troubleshoot and debug applications.</li><li>Perform UI/backend tests to optimize performance.</li><li>Client communication (verbal/written) as and when required.</li></ul>" },
    {
      id: "3",
      sequence_id: "3",
      title: "MERN Stack Developer",
      image: "mern.webp",
      experience: "2 years min",
      description:
        "We are looking for a MERN Stack Developer responsible for managing back-end services and the interchange of data between the server and the users. You will also be responsible for integrating the front-end elements built by your co-workers into the application. Therefore, a basic understanding of front-end technologies is necessary as well.",
      skills:
        "<ul><li>Node Js experience must required</li><li>M – MongoDB [ Database ]</li><li>E - Express JS [ Server side ] – node js web framework</li><li>R – React JS - a client side JavaScript framework</li><li>N - Node js [Back end technology] - the premier JavaScript web server.</li></ul>",
      other_skills: null,
      responsibilities: "",
    },
    {
      id: "4",
      sequence_id: "4",
      title: "Front end Developer",
      image: "front-end.webp",
      experience: "3 years min",
      description:
        "We are looking for a Front end Developer responsible for managing Front-end services and the interchange of data between the server and the users. You will also be responsible for integrating the front-end elements built by your co-workers into the application. Therefore, a basic understanding of front-end technologies is necessary as well.",
      skills:
        "<ul><li>Angular react js Ex. Required.</li><li>HTML/CSS</li><li>JavaScript / JQuery</li><li>Frameworks – CSS / JS framework</li><li>Responsive Design – CSS/ Bootstrap</li></ul>",
      other_skills: null,
      responsibilities: "",
    },
    {
      id: "5",
      sequence_id: "5",
      title: "React native Developer",
      image: "react.webp",
      experience: "1 to 4 years",
      description:
        "We are looking for a React native Developer responsible for managing Front-end services and the interchange of data between the server and the users. Your primary focus will be the development of all definition and maintenance of the central database, and ensuring high performance and responsiveness to requests from the front-end. You will also be responsible for integrating the front-end elements built by your co-workers into the application. Therefore, a basic understanding of front-end technologies is necessary as well.",
      skills:
        "<ul><h4>Junior Developer</h4><li>JavaScript coding (ES6 or later)</li><li>Knowing React Native basics (libraries, components, and APIs)</li><li>Use of a state management tool (for example, Redux)</li><li>The ability to navigate within a React Native app</li><li>Be ready for app debugging and testing</li><li>Optimizing app performance and code splitting</li><li>Working with mobile hardware (GPS, camera, gyroscope, and many others)</li><li>Operating databases, data caching processes, and libraries</li><li>Third-party service integration using their APIs</li><li>Taking over app deployment on App Store and Google Play Market</li><h4>Senior Developer</h4><li>Turning iOS and Android apps into React Native apps</li><li>Continuous integration (CI) and continuous delivery (CD)</li><li>Leadership and mentorship across development departments and \r\ngeneral workflow management</li><li>Native library and component integration</li><li>Research-oriented approach, a constant search for innovative \r\nsolutions</li><li>Public speaking and advanced communication skills</li><li>If expected salary more than 70 k then must have developed 5 react native \r\napplications.</li><li>Must have experience to publish mobile app on app store and play store.</li></ul>",
      other_skills: null,
      responsibilities: "",
    },
    {
      id: "6",
      sequence_id: "6",
      title: "Web Designer",
      image: "web-design.webp",
      experience: "1 year Min",
      description:
        "We are looking for a Web Designer responsible for managing Web Design services. Your primary focus will be the development of all definition and maintenance and ensuring high performance and responsiveness to requests from the front-end. You will also be responsible for integrating the front-end elements built by your co-workers into the application. Therefore, a basic understanding of front-end technologies is necessary as well.",
      skills:
        "<ul><li>HTML/ HTML5 & CSS / CSS3 & SAAS(SCSS) – Must required</li><li>JavaScript / Jquery</li><li>Web designing / development - Experience required</li><li>Content management system</li></ul>",
      other_skills:
        "<ul><li>Basic Designing Tools – Photoshop, Adobe IIustrator</li><li>UX/UI And Visual design</li><li>HTML5 game development is very good advantage</li></ul>",
      responsibilities: "",
    },
    {
      id: "7",
      sequence_id: "7",
      title: "Mean Stack Developer",
      image: "mean.webp",
      experience: "1 to 3 years",
      description:
        "We are looking for an Mean Stack Developer that will be able to develop and support existing applications. The main scope of work will include everything from back-end to client-side code, using optimal and efficient technologies, frameworks, and patterns. Your primary responsibilities will be to design and develop these applications. Therefore, it’s essential that you are skilled at problem solving, solution design, and high-quality coding",
      skills:
        "<ul><li>Node Js experience must required</li><li>M – MongoDB [ Database ]</li><li>E - Express JS [ Server side ] – node js web framework</li><li>A - Angular - a client side JavaScript framework – version 9+</li><li>N - Node js [Back end technology] - the premier JavaScript web server.</li></ul>",
      other_skills: null,
      responsibilities: "",
    },
  ];

  // Get JobList With details

  // const Getjoblist = () => {
  //     fetch('http://viapi.vidhaninfotech.com/api/get-jobs-with-details', {
  //         headers: {
  //             'Content-Type': 'application/json',
  //             'Accept': 'application/json'
  //         }
  //     })
  //         .then((response) => response.json())
  //         .then(Alljobslist => {
  //             if (Alljobslist.success) {
  //                 setJoblistData(Alljobslist.data)
  //             }

  //         });
  // }

  // Get resume

  const uploadResume = (e) => {
    setResume(e.target.files[0]);
  };

  // Apply Jobss

  const onSubmit = (data, e) => {
    e.preventDefault();

    setLoading(true);

    let fd = new FormData();
    fd.append("job", data.jobTitle);
    fd.append("name", data.name);
    fd.append("email", data.email);
    fd.append("mobile", data.mobile);
    fd.append("salary", data.currentSalary);
    fd.append("experience", data.totalExperience);
    fd.append("experience_in", data.experience);
    fd.append("resume", resume);
    fd.append("message", data.message);

    fetch(`http://viapi.vidhaninfotech.com/api/apply-now`, {
      method: "POST",
      body: fd,
      headers: {
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((Apply) => {
        if (Apply.success === true) {
          setLoading(false);
          setError("");
          Swal.fire({
            title: "Your application has been sent successfully.",
            text: "We'll analyze your information and get back to you ...",
            icon: "success",
            type: "success",
          });

          setAlert("");
          setApply(false);
          e.target.reset();
        } else {
          setLoading(false);
          setError(Apply.message);
          // Swal.fire({
          //     title: 'Oops...',
          //     text: Apply.message,
          //     type: 'alert',
          //     icon: 'error'
          // });
        }
      });
  };

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const Apply = (id) => {
    setApply(true);
  };
  const Close = () => {
    setApply(false);
  };

  const [viewmore, setViewmore] = useState("");
  const [toggle, setToggle] = useState(false);
  // const toggler = () => {}
  const ViewMore = (e) => {
    //    let postCopy = ;
    setToggle(!toggle);
    setViewmore(`career_box${e}`);
    // toggler.call(document.getElementById('career_box' + e));
  };
  return (
    <>
      <NavigationBar />
      <section className="career">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div class="title mb-4">
                <h4 class="line_title">Team</h4>
                <h1>
                  Join <span>Our Team</span>
                </h1>
                <p>Lets Get Started With Your Career</p>
              </div>
            </div>
          </div>

          <div className="row">
            {JobListData.map((jobdata, i) => (
              <div className="col-md-6 col-lg-4">
                <div
                  className={`career_box ${
                    viewmore === `career_box${i}` && toggle
                      ? "view_more_active"
                      : ""
                  }`}
                  id={`career_box${i}`}
                >
                  <h2>{jobdata.title}</h2>
                  <div className="d-flex mb-4">
                    <img src={experience} className="img-fluid" alt="" />
                    <h5>
                      <span className="title">Experience</span>
                      {jobdata.experience}
                    </h5>
                  </div>
                  <div className="d-flex">
                    <img src={description} className="img-fluid" alt="" />
                    <h5>
                      <span className="title">Description</span>{" "}
                      {jobdata.description}
                    </h5>
                  </div>
                  <div className="viewmore_data">
                    {jobdata.skills !== "" ? (
                      <ul
                        className={
                          jobdata.responsibilities !== "" ? "" : "mb-5"
                        }
                      >
                        <span className="title">Skills</span>
                        {parse(jobdata.skills)}
                      </ul>
                    ) : (
                      ""
                    )}
                    {jobdata.responsibilities !== "" ? (
                      <ul className="responsibility">
                        <span className="title">Responsibilities</span>
                        {parse(jobdata.responsibilities)}
                      </ul>
                    ) : (
                      ""
                    )}
                  </div>
                  <button
                    className="appy_now"
                    onClick={() => Apply(jobdata.id)}
                  >
                    Apply Now
                  </button>
                  <button className="view_more" onClick={() => ViewMore(i)}>
                    View More
                  </button>
                </div>
              </div>
            ))}
          </div>
          <div className={`apply_now_back ${apply ? "active" : ""}`}>
            <button className="close" onClick={Close}>
              +
            </button>
            <div className="apply_now">
              <form
                onSubmit={handleSubmit(onSubmit)}
                autoComplete="off"
                encType="multipart/form-data"
              >
                <div className="row">
                  <div className="col-md-12">
                    <select {...register("jobTitle", { required: true })}>
                      <option value="" selected disabled>
                        Select Job
                      </option>
                      {JobListData.map((jobdata, i) => (
                        <option id={jobdata.id} value={jobdata.id}>
                          {jobdata.title}
                        </option>
                      ))}
                    </select>
                    <span className="error_msg">
                      {errors.jobTitle?.type === "required" &&
                        "Job is required"}
                    </span>
                  </div>

                  <div className="col-md-6">
                    <input
                      {...register("name", { required: true })}
                      placeholder="Name"
                    />
                    <span className="error_msg">
                      {errors.name?.type === "required" && "Name is required"}
                    </span>
                  </div>

                  <div className="col-md-6">
                    <input
                      type="email"
                      {...register("email", { required: true })}
                      placeholder="Email"
                    />
                    <span className="error_msg">
                      {errors.email && "Email is required"}
                    </span>
                    {error.email && (
                      <span className="error_msg">{error.email}</span>
                    )}
                  </div>

                  <div className="col-md-6">
                    <input
                      {...register("mobile", { required: true })}
                      placeholder="Mobile Number"
                    />
                    <span className="error_msg">
                      {errors.mobile && "Mobile number is required"}
                    </span>
                  </div>

                  <div className="col-md-6">
                    <input
                      type="number"
                      min="1"
                      {...register("currentSalary", { required: true })}
                      placeholder="Monthly Salary"
                    />
                    <span className="error_msg">
                      {errors.currentSalary && "Salary is required"}
                    </span>
                  </div>

                  <div className="col-md-6">
                    <input
                      type="text"
                      min="1"
                      {...register("totalExperience", { required: true })}
                      placeholder="Total Experience"
                    />
                    <span className="error_msg">
                      {errors.totalExperience && "Total Experience is required"}
                    </span>
                    {error.experience && (
                      <span className="error_msg">{error.experience}</span>
                    )}
                  </div>

                  <div className="col-md-6">
                    <select
                      onChange={uploadResume}
                      {...register("experience", { required: true })}
                    >
                      <option value="" selected disabled>
                        Select MM/YY
                      </option>
                      <option value="year">Year</option>
                      <option value="month">Month</option>
                    </select>
                    <span className="error_msg">
                      {errors.experience?.type === "required" &&
                        "Experience is required"}
                    </span>
                  </div>

                  <div className="col-md-6">
                    <input
                      type="file"
                      id="files"
                      onChange={uploadPicture}
                      accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    />
                    {/* <span className='error_msg'>{errors.resume && "Image is required"}</span> */}
                  </div>

                  <div className="col-md-6">
                    <input
                      {...register("message", { required: true })}
                      placeholder="Message"
                    />
                    <span className="error_msg">
                      {errors.message && "Message is required"}
                    </span>
                  </div>
                  {error && <span className="error_msg">{error}</span>}
                  <div className="col-md-12">
                    <span className="error_msg">{alert}</span>
                  </div>
                  <div className="col-md-12">
                    <button
                      type="submit"
                      disabled={verified ? false : false}
                      className={loading ? "Loadingbtn" : ""}
                    >
                      {loading ? "Loading..." : "Send"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <NewFooter />
    </>
  );
}
export default React.memo(Career);
