import React, { useEffect, useState, useRef } from "react";
import "./index.scss";
import { NavLink, useLocation } from "react-router-dom";
import Meta from "../../../Components/Meta.jsx";
import Logo from "../../../Assets/Red_Theme/logo.svg";

function NavigationBar() {
  let menuData = [
    {
      mId: 1,
      menu: "Home",
      to: "/",
    },
    {
      mId: 2,
      menu: "About",
      to: "/about",
    },
    {
      mId: 3,
      menu: "Services",
      to: "/services",
    },
    {
      mId: 4,
      menu: "Portfolio",
      to: "/portfolio",
    },
    {
      mId: 5,
      menu: "Blog",
      to: "/blog",
    },
    {
      mId: 6,
      menu: "Career",
      to: "/career",
    },
  ];

  const [isNav] = useState(false);

  let activeClassName = "active";
  const location = useLocation();
  const { pathname } = location;
  const CurrentLocation = pathname.split("/");
  const [view, setView] = useState(false);


  useEffect(() => {
    const toRef = setTimeout(() => {
      setView(true);
      clearTimeout(toRef);
    }, 3000);
  }, [view]);
  const [verified, setVerified] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    if (verified) {
      const toRef = setTimeout(() => {
        setVerified(false);
        inputRef.current.value = "";
        clearTimeout(toRef);
      }, 30000);
    }
  }, [verified]);

  return (
    <React.Fragment>
      <Meta />
      <nav className="navbar navbar-expand-lg navbar-light">
        <div className="container">
          <NavLink className="navbar-brand" to="/">
            <img
              src={Logo}
            //   width={localStorage.getItem("windowWidth") < 500 ? "80" : "100"}
            //   height={localStorage.getItem("windowWidth") < 500 ? "57" : "71"}
              alt="Vidhan Infotech Pvt. Ltd. Blue Logo"
              title="Vidhan Infotech Pvt. Ltd. Blue Logo"
              tags="vidhan infotech pvt ltd,vidhan infotech in ahmedabad,software development company in gujarat,custom software development company in ahmedabad,website development company in ahmedabad,website development in ahmedabad, best website designer in ahmedabad"
            />
          </NavLink>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarTogglerDemo03"
            aria-controls="navbarTogglerDemo03"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className={`collapse navbar-collapse ${
              isNav ? "mobile_navigation active" : ""
            }`}
            id="navbarTogglerDemo03"
          >
            <ul className="nav navbar-nav ms-auto mb-2 mb-lg-0">
              {menuData.map((menuitem, i) => (
                <li className="nav-link" key={i}>
                  <NavLink
                    to={menuitem.to}
                    id={menuitem.mId}
                    className={`${
                      CurrentLocation[1] === menuitem.to ? "active" : ""
                    } ${({ isActive }) =>
                      isActive ? activeClassName : undefined}`}
                    aria-current="page"
                  >
                    {" "}
                    {menuitem.menu}{" "}
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </nav>
    </React.Fragment>
  );
}

export default React.memo(NavigationBar);
