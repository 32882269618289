import React, { useEffect, useState } from "react";
import "./App";
import { Routes, Route } from "react-router-dom";
import Home from "./Pages/Home/Home";
import About from "./Pages/About/About";
import Services from "./Pages/Services/Services";
import ViewServices from "./Pages/Services/ViewServices";
import Portfolio from "./Pages/Portfolio/Portfolio";
import Career from "./Pages/Career/Career";
import NotFound from "./Components/NotFound";
import Common from "./Pages/Common/Common";
import Fullstack from "./Pages/Services/Pages/Fullstack";
import ECommerce from "./Pages/Services/Pages/ECommerce";
import HireExperts from "./Pages/Services/Pages/HireExperts";
import InternetMarketing from "./Pages/Services/Pages/InternetMarketing";
import IOT from "./Pages/Services/Pages/IOT";
import MobileApp from "./Pages/Services/Pages/MobileApp";
import WebDesign from "./Pages/Services/Pages/WebDesign";
import WebDevelopment from "./Pages/Services/Pages/WebDevelopment";
import Blog from "./Pages/Blog/Blog";
import Viewblogs from "./Pages/Blog/Viewblogs";
import BecomePartner from "./Pages/BecomePartner";
import topArrow from "./Assets/Red_Theme/topArrow.png"

function App() {
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
      localStorage.setItem("windowWidth", windowSize.innerWidth);
      localStorage.setItem("windowHeight", windowSize.innerHeight);
    }
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [windowSize]);

  const [view, setView] = useState(false);
  useEffect(() => {
    const toRef = setTimeout(() => {
      setView(true);
      clearTimeout(toRef);
    }, 5000);
  }, [view]);

  // Show button when page is scrolled down
  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Scroll to top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  return (
    <>
      <React.Fragment>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/about/:id" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/career" element={<Career />} />
          <Route path="/viewservices/:id" element={<ViewServices />} />
          <Route path="/full-stack" element={<Fullstack />} />
          <Route path="/e-commerece" element={<ECommerce />} />
          <Route path="/hire-experts" element={<HireExperts />} />
          <Route path="/internet-marketing" element={<InternetMarketing />} />
          <Route path="/iot" element={<IOT />} />
          <Route path="/mobile-app" element={<MobileApp />} />
          <Route path="/web-design" element={<WebDesign />} />
          <Route path="/web-development" element={<WebDevelopment />} />
          <Route path="/common" element={<Common />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:id" element={<Viewblogs />} />
          <Route path="/become-partner" element={<BecomePartner />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </React.Fragment>
      <div className="scroll-to-top">
        {isVisible && (
          <button onClick={scrollToTop} className="scroll-button">
            <img src={topArrow} className="img-fluid" />
          </button>
        )}
      </div>
    </>
  );
}
export default React.memo(App);

function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}
