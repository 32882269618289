import React from 'react'
import Footer from '../Home/Footer'
import Navigation from '../Home/Navigation'
import './common.scss';
// import 'boxicons';
import { NavLink } from 'react-router-dom';

function Common() {
    const anatomy = [
        { id: 1, name: 'OPENSOURCE', icon: 'code-block', },
        { id: 2, name: 'GOOD COMMUNITY SUPPORT', icon: 'group', },
        { id: 3, name: 'COST EFFECTIVE', icon: 'dollar-circle', },
        { id: 4, name: 'FAST AND SCALABLE', icon: 'expand-alt', },
        { id: 5, name: 'ONE LANGUAGE FOR ALL LAYERS', icon: 'font', },
        { id: 6, name: 'UNIFORM PLATFORM', icon: 'layer', },
        { id: 7, name: 'ISOMORPHIC CODING', icon: 'code-alt', },
        { id: 8, name: 'SUPPORTS MVC ARCHITECTURE', icon: 'shape-polygon', },
    ]
    const technical = [
        { id: 1, name: 'MONGODB', icon: 'mongodb', desc: 'Our MEAN experts use MongoDB – a NoSQL database because it stores data similarly to the JSON format. As applications evolve over time, the dynamic schemas inherent in MongoDB evolve along with it.', },
        { id: 2, name: 'EXPRESS JS', icon: 'javascript', desc: 'Our developers love to use Express as it is a flexible Node.js web application framework to develop web and mobile applications with a robust set of features for businesses of any type or size.', },
        { id: 3, name: 'ANGULAR JS', icon: 'angular', desc: 'Our team uses Angular to extend basic HTML functionality to more easily work with the dynamic views inherent in web apps. Angular JS can also be extended easily.', },
        { id: 3, name: 'NODE.JS', icon: 'nodejs', desc: 'Developers at Vidhan infotech create server-side Javascript web applications using the Node.js runtime environment. Since Node.js is asynchronous, it is far more scalable than traditional thread-based networking.', },
    ]
    return (
        <>
            <Navigation />
            <section className='view_services'>
                <div className="container">
                    <div className="common_heading service_heading text-center mt-3">
                        <h1 className=''>
                            MEAN STACK <span>DEVELOPMENT COMPANY</span>
                        </h1>
                    </div>
                    <div className="common_content text-center">
                        <p className='mt-2'>Building the Best Digital Solutions for Your Business with MEAN Stack Development Services for Digital Transformation</p>
                    </div>
                    <div className="common_content">
                        <div className="row mt-4" id="">
                            <div className="col-md-12">
                                <div className="content_text">
                                    <h1><span>Create A Robust, Scalable And Dynamic Web Apps</span> Leverage The Power Of MEAN Stack Web Development</h1>
                                    <p>MEAN Stack is the modern software stack and a compilation of four advanced JavaScript-based technologies MongoDB, Express JS, Angular JS and Node.js. MEAN provides a full stack web application development environment for building fast, secure and scalable applications using single language across all the layers. Vidhan infotech provides end-to-end MEAN Stack development services using a clean code base prototyped by MEAN experts for your online business.</p>
                                </div>
                                <div className="content_text">
                                    <h1>What Is The Anatomy Of The MEAN Stack Web Application?</h1>
                                    <p>The MEAN stack application structure is a combination of MongoDB – a NoSQL database that provides flexible data storage layers, Express JS – that standardizes web application development, Angular JS – that allows interactive gestures on web applications and Node.js – that keeps your server running continuously, and together, they form the MEAN stack.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {
                            anatomy.map((item, index) => {
                                return (
                                    <div className="col-md-3">
                                        <div className="anatomy_item" key={item.id}>
                                            <div className="anatomy_icon">
                                                <box-icon name={item.icon}></box-icon>
                                            </div>
                                            <div className="anatomy_text">{item.name}</div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                        <div className="col-md-12">
                            <div className="common_content">
                                <div className="row mt-4" id="">
                                    <div className="col-md-12">
                                        <div className="content_text">
                                            <h1><span>Thrive Mean Stack Development To Enable Quicker</span> And Glass Case Technical Innovation</h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                {
                                    technical.map((item, index) => {
                                        return (
                                            <div className="col-md-3">
                                                <div className="technical_item" key={item.id}>
                                                    <div className="technical_icon">
                                                        <NavLink to="home">
                                                            <box-icon type="logo" name={item.icon}></box-icon>
                                                        </NavLink>
                                                    </div>
                                                    <div className="technical_title">{item.name}</div>
                                                    <div className="technical_desc">{item.desc}</div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="hore_experts">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="hire_ex_content">
                                <div className="title"><span>Hire Our Expert</span> MEAN Stack Developers</div>
                                <div className="desc">Our MEAN developers are inspired to create awesome experiences using the key principles of Agile. Vidhan Infotech is your MEAN Stack development partner and we strive to build websites and applications that deliver rich user experience and has more dynamic features. Hire our developers to empower your server-side and drive innovative business growth.</div>
                                <button>Hire Now</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
export default React.memo(Common)