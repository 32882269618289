
import React, {memo} from 'react'
import Footer from '../../Home/Footer'
import Navigation from '../../Home/Navigation'

function Fullstack() {
  return (
    <>
    <Navigation/>
    <div style={{height: '100vh', padding: '100px 0 0 0', color: '#fff', fontSize: '100px'}}>Fullstack</div>
    <Footer/>
    </>
  )
}
export default React.memo(Fullstack)