import React, { memo } from "react";
import NavigationBar from "../Shared/NavigationBar";
import NewFooter from "../Shared/NewFooter";
import { useParams } from "react-router-dom";
import Star from "./../../Assets/Images/star.svg";
import parse from "html-react-parser";
import shortid from "shortid";

function ViewServices() {
  let { id } = useParams();

  const Services = [
    {
      id: "application-development",
      servicetitle: "Application Development",
      servicecontent:
        "We offer a wide range of software development services that offer industry-specific solutions that align individuals, processes, and systems to scale and grow your business to a professional level.",
      app: [
        {
          image: require("../../Assets/Images/android_app.webp"),
          alt: "App Development Service at Vidhan Infotech",
          cap: "App Development Service at Vidhan Infotech",
          id: "1",
          title: "Android App Development",
          description: [
            {
              desc: "You need a knowledgeable robot app development company to launch your robot app in the style of an Associate in a Nursing player or full-fledged product, especially with thousands of robot apps on the market. By adding the part of WOW, <a href='http://vidhaninfotech.com' target='_black'>Vidhan Infotech</a> develops apps that individuals like to see once more and once more. We tend to take you on the far side of the bounds of excellence, and our robot <a href='http://developer-android.unlimited-translate.org/training/run-background-service/index.html' target='_blank'>app development services</a> settle for nothing less.",
            },
          ],
        },
        {
          image: require("../../Assets/Images/apple_app.webp"),
          alt: "IOS App Development Service at Vidhan Infotech",
          cap: "IOS App Development Service at Vidhan Infotech",
          id: "2",
          title: "iOS App Development",
          description: [
            {
              desc: "Vidhan Infotech stands tall as an Associate in Nursing iPhone development company, taking daring steps from planning pixel-perfect styles to intuitive backends, to fill every dimension with the fashionable iOS app development realm. With the standard that Apple devices have, our iPhone development services are sure to set the bar high with a product that's daring, intuitive, timely, and innovative.",
            },
          ],
        },
        {
          id: "3",
          image: require("../../Assets/Images/hybrid.webp"),
          alt: "Hybrid Development Service at Vidhan Infotech",
          cap: "Hybrid Development Service at Vidhan Infotech",
          title: "Hybrid App Development",
          description: [
            {
              desc: "A hybrid app could be a packaged application that mixes components of both native and internet applications. Hybrid apps are internet apps that are placed into a native app shell. Hybrid apps are standard. As a result, they permit developers to put in writing code for a mobile app once and still accommodate multiple platforms.",
            },
          ],
        },
        {
          id: "4",
          image: require("../../Assets/Images/rapid_application.webp"),
          alt: "Rapid Application Development Service at Vidhan Infotech",
          cap: "Rapid Application Development Service at Vidhan Infotech",
          title: "Rapid Application Development",
          description: [
            {
              desc: "The RAD (Rapid Application Development) model follows the Associate in Nursing progressive approach. You'll be able to at the same time develop the individual modules of the appliance to allow for quicker delivery of the ultimate product.",
            },
          ],
        },
        {
          id: "5",
          image: require("../../Assets/Images/web_application.webp"),
          alt: "Web Application Development Service at Vidhan Infotech",
          cap: "Web Application Development Service at Vidhan Infotech",
          title: "Web Application Development",
          description: [
            {
              desc: "Web applications are packages developed for a particular purpose and hosted on an internet server. They'll be accessed via any internet-enabled device, like phones, laptops, and desktops. Some examples of internet applications are online banking sites, e-commerce websites, and social networking sites.",
            },
          ],
        },
      ],
      lifeCycle: [
        {
          id: "1",
          title: "Discovery Phase",
          desc: "The discovery or beginning part of the mobile app development lifecycle is concerning the refinement of the idea for the app. To make a product, you need to raise some basic queries.",
        },
        {
          id: "2",
          title: "Style Phase",
          desc: "Create an advancement style and chart for the project to line up boundaries for sure responsibilities. The role of the team, core responsibilities, and functions are spelled out and documented during this stage.",
        },
        {
          id: "3",
          title: "The event and testing phase",
          desc: "The development method is alternate and unvaried. It goes hand in hand with testing, so any bugs or errors within the program are often caught and corrected at the earliest chance.",
        },
        {
          id: "4",
          title: "The Readying App Development Phase",
          desc: "Once undergoing rigorous development and testing, the app is prepared to be released to the globe. It’s now that you just would like the sales and advertising team to assist in popularizing the app. The official unharness date for the app ought to represent the climax of the appliance selling efforts to the present time.",
        },
        {
          id: "5",
          title: "Maintenance and Updates Phase",
          desc: "The lifecycle of appliance development doesn't finish with the purification of your product. It’s vital that once your product gets into the hands of the users, you continue providing necessary updates and maintenance.",
        },
      ],
      Benefits: [
        {
          id: "1",
          title: "Benefits for App Development Services With Vidhan Infotech",
          List: [
            {
              desc: "Vidhan Infotech could be a package development company specializing in building up-to-date package solutions for our purchasers.",
            },
            {
              desc: "At intervals in the sector of app development, we tend to facilitate our purchasers with internet development, mobile development, UI/UX style, IT consulting, and more.",
            },
            {
              desc: "Our knowledgeable engineering groups use to deliver quality packages each time. Vidhan Infotech's powerful combination of domain data, technical experience, and knowledgeable internet and mobile app developers implies that all our purchasers receive custom-made <a href='https://www.vidhaninfotech.com/services'>package services</a> that add tangible value to their business",
            },
          ],
        },
      ],
    },
    {
      id: "custom-website-design",
      servicetitle: "Custom Web Design",
      servicecontent:
        "Vidhan Infotech combines talent, understanding, and knowledge to supply leading-edge visuals in web design and wireframes.",
      app: [
        {
          image: require("../../Assets/Images/custom_web.webp"),
          alt: "Make a Custom Web Design at Vidhan Infotech",
          cap: "Make a Custom Web Design at Vidhan Infotech",
          id: "1",
          title:
            "Make Your Business More Accessible With Responsive Web Design.",
          description: [
            {
              desc: "The world these days is on the desktop. Many folks do net browsing on mobile devices for simple accessibility. <a href='http://vidhaninfotech.com' target='_black'>Vidhan Infotech</a> provides a superb solution to make your business stronger. We have experts in the <a href='https://www.cfr.org/backgrounder/what-does-world-health-organization-do' target='_blank'>World Health Organization</a> who can recognize the wants and specify the business highlights by adjusting the resolution to encourage the best user experience. We get a swish style that works for multiple interfaces.",
            },
          ],
        },
      ],
      CustomWebServices: [
        {
          title: "Custom Web Design Services",
          customServices: [
            {
              id: "1",
              title: "A custom style",
              desc: "Your website should reflect your distinctive business style, and we will make that happen for you. Tell America your business needs together with your tailor-made vogue and that we can deliver the website that represents your website.",
            },
            {
              id: "2",
              title: "A Custom Web Design",
              desc: "Our network designers ensure the best SEO results. Our custom website styles are so engaging that guests keep their eyes away from them!",
            },
            {
              id: "3",
              title: "Banner Style Services",
              desc: "Our designers first learn about your banner needs before creating banners for websites, mobile devices, landing pages, and social media. This way, we tend to assist you in positioning your stigmatization efforts in the right direction.",
            },
            {
              id: "4",
              title: "Logo-Style Services",
              desc: "We deliver high-quality and precise emblem styles that assist you with the needs of your target market and grow the recognition of your business. Our designers have the superior skill to form extremely exclusive banners in line with your business needs.",
            },
            {
              id: "5",
              title: "Social Media Style",
              desc: "Our social media styling services assist you with your products and services in the very best manner on putative social media platforms. The posts thus created are so engaging that your target market might find it difficult to ignore them.",
            },
            {
              id: "6",
              title: "Mobile Website Style Services",
              desc: "It is essential to create your website optimized for various kinds of devices. We tend to perceive your specific business needs whenever our mobile website-style services can be valuable.",
            },
            {
              id: "7",
              title: "E-Commerce Web Design",
              desc: "Do you wish to form an optimized yet engaging eCommerce store that catches the eye of the users instantly? Let our professional web designers assist you with an <a href='https://www.vidhaninfotech.com/viewservices/ecommerce-services' target='_blank'>eCommerce store</a> with an unmatched design and a sexy interface.",
            },
            {
              id: "8",
              title: "Responsive Web Design",
              desc: "Millennials today use mobile phones to access websites, and if your website is mobile-friendly, you may lose an outsized chunk of your target market. It will enable us to create a responsive site across all platforms for your business.",
            },
          ],
        },
      ],
      Benefits: [
        {
          id: "1",
          title: "Important website options to scale your business.",
          desc: "Your website is to grow alongside your company. Each website we tend to style and develop has:",
          List: [
            {
              desc: "Thumb-Friendly Mobile Websites",
            },
            {
              desc: "SEO-Friendly Websites",
            },
            {
              desc: "Easy to Update Websites",
            },
            {
              desc: "Third-Party Tool prepared Websites",
            },
            {
              desc: "eCommerce Websites",
            },
            {
              desc: "Content Management Systems.",
            },
            {
              desc: "Powerful Website Hosting Services",
            },
          ],
        },
      ],
      titledPoint: [
        {
          id: "1",
          title: "The Benefits of Responsive Web Design",
          List: [
            {
              id: "1",
              title:
                "Develop your variety for the tablet and telephone audience.",
              desc: "The increasing utilization of the internet and the multiplication of online applications on tablets and cell phones have been the main thrust behind this turn of events. Purchasers would go to a gadget-specific website (for example, mobile). However, the responsive style implies one website across gadgets. A number one responsive website company keeps this in mind.",
            },
            {
              id: "2",
              title: "Advanced deals and conversion rates:",
              desc: "An added advantage of a responsive style is that the consumer has additional website insight. There is no demand for redirection; normalized vogue sheets (CSS) across gadgets and a unified layout can likewise manufacture a gentle look and feel. Responsive website-style services facilitate spicing up sales.",
            },
            {
              id: "3",
              title: "Simplified analysis and documenting:",
              desc: "website investigation tools like Google Analytics have increased to alter numerous gadgets and responsive reportage. The complete results and examination are shaped into one report, considering observation and investigation.",
            },
            {
              id: "4",
              title: "Reduced bounce rate:",
              desc: "A high-responsive website style company offers the guest immensely improved consumer expertise. Maintains your availability for a long time and investigates various aspects of your website. If your website is not responsive, it makes it tougher to keep the guests drawn in and, hence, nearly impossible for them to bounce. Vidhan, the most effective responsive website style company, helps decrease bounce rates by building super responsive website styles.",
            },
            {
              id: "5",
              title: "Additionally, developed SEO:",
              desc: "Responsive website design is becoming as essential to site improvement as a high-quality substance. Several ground-bound backlinks and higher bounce rates convert into higher search rankings. Yet, there's a further SEO profit for <a href='https://www.fdsc.net/mobile-friendly-websites' target='_blank'>mobile-friendly sites</a>. A number one responsive website company can forever keep this time in mind.",
            },
          ],
        },
      ],
    },
    {
      id: "software-development",
      servicetitle: "Software Development",
      servicecontent:
        "Being an Award winning, Business software development company we always deliver success with smile, commitment and dedication.",
      app: [
        {
          image: require("../../Assets/Images/software-dev.webp"),
          alt: "Enterprise Software Development at Vidhan Infotech",
          cap: "Enterprise Software Development at Vidhan Infotech",
          id: "1",
          title: "Software Development",
          description: [
            {
              desc: "We are an associated enterprise with a software development firm. We tend to invariably deliver success with a delegated approach, commitment, and dedication.",
            },
          ],
        },
      ],
      fiveForms: [
        {
          id: "1",
          title: "5 forms of software development",
          List: [
            {
              id: "1",
              title: "Frontend Development",
              descriptions: [
                {
                  desc: "Front-end developers work on a part of the merchandise wherever the user interacts. They're primarily involved with the computer program (UI). As an example, they may produce the layout, visual aspects, and interactive parts of a website or app. However, their role isn’t just like that of a <a href='https://www.vidhaninfotech.com/viewservices/custom-website-design' target='_blank'>UI or user experience (UX) designer</a>. They additionally fix bugs and ensure that the UI will run on completely different browsers.",
                },
              ],
            },
            {
              id: "2",
              title: "Back-end Development",
              descriptions: [
                {
                  desc: "Back-end development involves writing, optimizing, and deploying the knotty server-side software. To do this, you would like a team of specialists well versed in writing advanced practical protocols, building libraries, and developing alternative deep elements, that are instrumental in providing subject consistency and an outlined operational structure targeted at rising performance. If you're wanting to strengthen your application landscape with higher-quality back-end solutions, trust <a href='http://vidhaninfotech.com' target='_black'>Vidhan Infotech</a> with your needs.",
                },
              ],
            },
            {
              id: "3",
              title: "Full Stack Development",
              descriptions: [
                {
                  desc: "Full-stack development necessitates a thorough knowledge of both the <a href='https://www.bitdegree.org/tutorials/front-end-vs-back-end-developer/' target='_blank'>front-end and back-end technologies</a>. They must all be fluent in programming languages, or at the very least the most widely used. They have to be sensible at operating with everything, starting from databases to users, and in the middle of everything.",
                },
              ],
            },
            {
              id: "4",
              title: "Web Development",
              descriptions: [
                {
                  desc: "Web development refers generally to the tasks related to developing websites for hosting via an associated computer network or the web. The internet development method includes web style, web page development, client-side and server-side scripting, and network security configuration, among alternative tasks.",
                },
                {
                  desc: "In a broader sense, net development encompasses all the actions, updates, and operations needed to make, maintain, and manage a website to ensure its performance, user expertise, and speed are optimum. It might additionally, but not essentially, embody all those strategic actions required to confirm its correct ranking in program results. Usually, those tasks pertain to a unique specialization, specifically program optimization (SEO).",
                },
              ],
            },
            {
              id: "5",
              title: "Database Development",
              descriptions: [
                {
                  desc: "The agency typically works on daily information maintenance and troubleshooting and implementing the system. An information developer is accountable for building the information, modifying and planning existing or new programs, and making certain that they satisfy the needs of the users. Sometimes, the roles of information <a href='https://hfs.uw.edu/getmedia/4270414e-ff76-404e-a43d-09aa4dba9195/PD-DBD-Hall-FK.pdf.aspx' target='_blank'>administrator and developer</a> do overlap; this depends on the wants of the shopper or leader.",
                },
              ],
            },
          ],
        },
      ],
      developmentLifeCycle: [
        {
          id: "1",
          title: "The Software Development Life Cycle (SDLC)",
          desc: "The Software Development Life Cycle (SDLC) could be a structured method that allows the assembly of high-quality, cheap software within the shortest potential time. The goal of the SDLC is to supply superior software that meets and exceeds all client expectations and demands. The SDLC defines and describes an in-depth arrangement that features stages, or phases, each with its method and deliverables.",
        },
      ],
      developmentVidhan: [
        {
          id: "1",
          title: "Software Development at Vidhan Infotech",
          desc: "We offer software development services to assist you in expanding your business, increasing your return on investment, and gaining a competitive advantage. We tend to facilitate each start-up and enormous business organization. The <a href='http://vidhaninfotech.com' target='_black'>Vidhan Infotech</a> team assists you at each stage of software development. Whether you would like to facilitate conceptualization or not, we tend to be here for you.",
        },
      ],
      SDLC: [
        {
          title: "How will the SDLC work?",
          customServices: [
            {
              id: "1",
              title: "Planning Phase",
              desc: "The planning section encompasses all aspects of project and product management. This includes resource allocation, capability designing, project planning, price estimation, and provisioning.During the look section, the event team collects input from stakeholders concerned with the project; customers, sales, internal and external specialists, and developers.",
            },
            {
              id: "2",
              title: "Coding Phase",
              desc: "The cryptography section includes system style in an integrated development setting. It additionally includes static software analysis and code review for multiple types of devices.",
            },
            {
              id: "3",
              title: "Building Phase",
              desc: "The building section takes the software that needs to be determined earlier and uses it to start building the code.",
            },
            {
              id: "4",
              title: "Testing Phase",
              desc: "This analysis section entails the analysis of the created software. The testing team evaluates the developed product (s) to assess whether they meet the needs per the 'planning' section.",
            },
            {
              id: "5",
              title: "Release Phase",
              desc: "The release section involves the team packaging, managing, and deploying releases across completely different environments.",
            },
            {
              id: "6",
              title: "Deploy Phase",
              desc: "In the reading section, the software is formally discharged into the assembly setting.",
            },
            {
              id: "7",
              title: "Operate Phase",
              desc: "The operation section entails the utilization of the code within the production setting.",
            },
            {
              id: "8",
              title: "Monitor Phase",
              desc: "In the observation section, various parts of the software are monitored. These may embody the general system performance, user expertise, new security vulnerabilities, and associated analysis of bugs or errors within the system.",
            },
          ],
        },
      ],
      Benefits: [
        {
          id: "1",
          title: "Why is the SDLC important?",
          desc: "",
          List: [
            {
              desc: "It provides a uniform framework that defines activities and deliverables",
            },
            {
              desc: "It aids in project designing, estimating, and planning.",
            },
            {
              desc: "It makes project tracking and management easier.",
            },
            {
              desc: "It will increase visibility on all aspects of the life cycle to any or all stakeholders concerned within the development method.",
            },
            {
              desc: "It will increase the speed of development.",
            },
            {
              desc: "It improves shopper relations.",
            },
            {
              desc: "It decreases project risks.",
            },
            {
              desc: "It also decreases project management expenses and the overall price of production.",
            },
          ],
        },
      ],
    },
    {
      id: "enterprise-resource-planning",
      servicetitle: "Enterprise Resource Planning",
      servicecontent:
        "We have a team of skilled developers. <a href='http://vidhaninfotech.com' target='_black'>Vidhan Infotech</a> will work on small-scale, international ERP <a href='https://www.vidhaninfotech.com/viewservices/software-development' target='_blank'>software system</a> applications.",
      app: [
        {
          image: require("../../Assets/Images/enterprice.webp"),
          id: "1",
          title: "Enterprise Resource Planning at Vidhan Infotech",
          description: [
            {
              desc: "Enterprise resource planning (ERP) refers to a sort of <a href='https://www.vidhaninfotech.com/viewservices/software-development' target='_blank'>software system</a> that organizations use to manage day-to-day business activities like accounting, procuring, project management, risk management, compliance, and supply chain operations.",
            },
          ],
        },
      ],
      SDLC: [
        {
          id: "1",
          title: "The Benefits of Enterprise Resource Planning",
          customServices: [
            {
              id: "1",
              title: "Effectiveness and quality",
              desc: "Integrating and improving the company's internal business processes leads to important enhancements in quality and potency. ERP creates a framework to integrate and improve the company's internal business processes, which results in essential enhancements in quality and potency in client service, production, and distribution.",
            },
            {
              id: "2",
              title: "Decrease in prices",
              desc: "ERP is helpful in considerably reducing dealings and hardware process prices, as well as a software system and IT, support employee numbers.",
            },
            {
              id: "3",
              title: "Call Support",
              desc: "ERP provides info regarding cross-functional business performance that helps improve par ability. The manager takes selections promptly.",
            },
          ],
        },
      ],
      developmentVidhan: [
        {
          id: "1",
          title: "How Do ERP Systems Work?",
          desc: "ERP systems, generally referred to as ERP suites, incorporate interlinked software system applications that share one piece of information. There are three basic styles of ERP.",
        },
      ],
      titledPoint: [
        {
          id: "1",
          List: [
            {
              id: "1",
              title: "Cloud-based ERP:",
              desc: "Remote information centers facilitate the employment of online tools that cloud-based ERPs use. By maintaining information and software systems within the cloud, a corporation will cut back on the quantity of hardware required for day-to-day operations. <a href='https://www.engpaper.net/cloud-server.html' target='_blank'>Cloud-based servers</a> are thought to be less at risk of cyberattacks.",
            },
            {
              id: "2",
              title: "On-premise ERP:",
              desc: "Some corporations opt to run ERP networks through their non-public information centers for optimum management. This feature typically needs a bigger up-front investment in hardware. This model combines cloud-based and on-premises ERP choices. This technique is typically referred to as a two-tier ERP.",
            },
          ],
        },
      ],
      erpappserv: [
        {
          id: "1",
          tit: "End-to-end Enterprise App Development Services",
          List: [
            {
              id: "1",
              title1: "Enterprise application consulting",
              descriptions: [
                {
                  desc: "Bring technology-driven business transformation to life with our 360-degree enterprise application consulting.",
                },
                {
                  desc: "We offer bespoke professional consultation to assist you in selecting the correct design, frameworks, platforms, and agile methodologies to assist you in developing a future-ready enterprise application that aligns with all of your advanced business needs.",
                },
              ],
            },
            {
              id: "2",
              title1: "B2B mobile application development",
              descriptions: [
                {
                  desc: "Witness business growth through economic analytics and higher user engagement with <a href='https://www.vidhaninfotech.com' target='_blank'>Vidhan Infotech</a> Our pre-vetted development groups modify you to style and develop, customize, and integrate easy B2B <a href='https://www.vidhaninfotech.com/viewservices/application-development' target='_blank'>mobile applications</a> with machine-controlled operations for property service delivery.",
                },
              ],
            },
            {
              id: "3",
              title1: "Enterprise application integration",
              descriptions: [
                {
                  desc: "Manage vital business functions with additional accuracy and deploy a sturdy knowledge assortment system with our advanced enterprise application integration services.",
                },
                {
                  desc: "We work with you to secure continuous knowledge flow between multiple <a href='https://its.appstate.edu/cio/about/central-it/enterprise-applications' target='_blank'>enterprise applications</a>, as well as ERP and CRM tools, to contour your business processes and eliminate information silos.",
                },
              ],
            },
            {
              id: "3",
              title1: "Enterprise Application Testing",
              descriptions: [
                {
                  desc: "Prepare your application to handle advanced digital workflows, multi-level business needs, and new changes with a comprehensive testing strategy.",
                },
                {
                  desc: "We modify you to leverage automation and DevOps to form an extremely effective take a look at atmosphere to confirm consistent performance and bug-free user expertise for your users at any hour of the day or night!",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: "e-commerce",
      servicetitle: "Ecommerce Services",
      servicecontent:
        "Websites are good, but e-commerce sites that pay are even better. We are here to turn your investment into a profit-making opportunity.",
      app: [
        {
          image: require("../../Assets/Images/ecommerce.webp"),
          id: "1",
          title: "E-commerce Platform at Vidhan Infotech",
          description: [
            {
              desc: "E-commerce (electronic commerce) refers to the buying and selling of goods and services, and the transmission of funds or data, over an electronic network, most commonly the internet. These business transactions occur either as business-to-business (B2B), business-to-consumer (B2C), consumer-to-consumer, or consumer-to-business.",
            },
          ],
        },
      ],
      erpappserv: [
        {
          id: "1",
          List: [
            {
              id: "1",
              title: "E-Commerce Roadmap",
              descriptions: [
                {
                  desc: "The e-commerce trade has been directly impacting small and medium enterprises (SMEs) by providing suggestions for funding, technology, and coaching, and has had a positive cascading result on alternative industries in addition.",
                },
                {
                  desc: "The Indian e-commerce trade has been on an Associate in Nursing's upward growth mechanical phenomenon and is predicted to surpass the United States of America to become the second-largest e-commerce market in the world by 2034. Technology-enabled innovations like digital payments, hyper-local supply, analytics-driven client engagement, and digital advertisements can probably support the expansion of the sector.",
                },
                {
                  dessc:
                    "The expansion within the e-commerce sector will boost employment, increase revenues from exports, increase assembling by exchequers, and supply higher quality merchandise and <a href='https://www.vidhaninfotech.com/services' target='_blank'>services</a> to customers in the long run. An increase in smartphone usage is predicted to rise eighty-four to reach 859 million by 2022.",
                },
              ],
            },
          ],
        },
      ],
      developmentVidhan: [
        {
          id: "1",
          title: "Types of E-commerce",
          desc: "The revolutionary e-commerce business models of Amazon and eBay affected online retail. Its light-emitting diode to firms across the <a href='https://www.imd.org/research-knowledge/articles/developing-unique-business-models--going-beyond-servicessolutions/' target='_blank'>business model</a> spectrum recognizes that e-commerce might enhance business. The result is that there are currently many varieties of e-commerce. Here’s a fast look at each.",
        },
      ],
      erpappserv: [
        {
          id: "1",
          List: [
            {
              id: "1",
              title: "Business-to-Consumer (B2C) e-commerce website",
              descriptions: [
                {
                  desc: "B2C e-commerce is perhaps what involves the mind once you first hear the term 'e-commerce.' It refers to merchandise or <a href='https://www.vidhaninfotech.com/services' target='_blank'>services</a> marketed by a business to individual shoppers.",
                },
                {
                  desc: "Many well-liked examples illustrate B2C e-commerce. As an example, if you head to Amazon or Walmart’s website to buy one thing, that’s B2C e-commerce in action. A similar is true for many things that you just purchase online for private use. If it’s sold by an organization, then it fits in with this kind of e-commerce.",
                },
                {
                  desc: "B2C e-commerce is what started it all. From the Electronic Mall to the primary official online purchase of the type of Sting album, those early examples stand unmoving in B2C e-commerce.",
                },
              ],
            },
            {
              id: "2",
              title: "Business-to-Business (B2B) e-commerce",
              descriptions: [
                {
                  desc: "Not all businesses market to individual shoppers. Some sell merchandise and <a href='https://www.vidhaninfotech.com/services' target='_blank'>services</a> to alternative firms. Once that takes place online, you have got B2B e-commerce.",
                },
                {
                  desc: "One example of B2B e-commerce is net <a href='https://www.vidhaninfotech.com/viewservices/software-development' target='_blank'>development</a>. Each company desires an internet site, partly due to, ironically, ideas like e-commerce. If an organization needs to have a virtual presence, it’ll have to build a website.",
                },
                {
                  desc: "That business may shop around for an online <a href='https://www.vidhaninfotech.com/viewservices/software-development' target='_blank'>development</a> company that has expertise in e-commerce, and if they notice one online that they rent, that’s B2B e-commerce in action. The online <a href='https://www.vidhaninfotech.com/viewservices/software-development' target='_blank'>development</a> company provided a product for one more business.",
                },
                {
                  desc: "The same basic plan happens in alternative industries. Your typical local business might purchase workplace instruments online from a merchandiser like Xerox. If that native business desires vehicles to perform core functions, they may order components, like tires, online from an organization like a discoverer.",
                },
                {
                  desc: "Xerox and Discoverer, in those examples, are collaborating in B2B e-commerce. Of course, those two firms are concerned with B2C e-commerce.",
                },
              ],
            },
            {
              id: "1",
              title: "Consumer-to-Business (C2B) E-commerce",
              descriptions: [
                {
                  desc: "Individual customers will typically sell merchandise and <a href='https://www.vidhaninfotech.com/services' target='_blank'>services</a> to businesses rather than businesses selling merchandise and <a href='https://www.vidhaninfotech.com/services' target='_blank'>services</a> to customers online (B2C e-commerce).",
                },
                {
                  desc: "One example is paid reviews. If a client owns his or her journal, that person might approach a relevant business with a chance to put a product or service in the journal. The business pays the individual for the journal post, video, or podcast, and edges from the selling chance. As a result of all of that happening online, it qualifies as C2B e-commerce.",
                },
                {
                  desc: "Another exemplar of C2B e-commerce reflects a preferred trend for people to earn or supplement their living. Platforms have emerged that enable freelancers to plug their skills into purchasers.",
                },
                {
                  desc: "On those websites, freelance photographers, writers, developers, and a lot of others can secure assignments from businesses. It's referred to as C2B e-commerce as a result of people's square measure of the commercialism of merchandise and <a href='https://www.vidhaninfotech.com/services' target='_blank'>services</a> to businesses online.",
                },
              ],
            },
            {
              id: "1",
              title: "Consumer-to-Consumer (C2C) E-commerce",
              descriptions: [
                {
                  desc: "If shoppers sell merchandise and <a href='https://www.vidhaninfotech.com/services' target='_blank'>services</a> to businesses online in C2B e-commerce, it’s solely a small step to increase shoppers' commercialism to alternative shoppers.",
                },
                {
                  desc: "An easy way to illustrate C2C e-commerce as opposed to illustrating C2C e-commerce via freelance platforms in the previous type of e-commerce In C2C e-commerce, rather than freelancers commercializing their merchandise and <a href='https://www.vidhaninfotech.com/services' target='_blank'>services</a> to businesses, they have interaction at work with alternative shoppers. Think about a client whose <a href='https://en.wikipedia.org/wiki/List_of_specialized_agencies_of_the_United_Nations' target='_blank'>UN agency</a> needs a professionally designed graduation party announcement or a family website engineered. Neither party is a long-time business person.",
                },
                {
                  desc: "Other examples include square measurement and a lot of simplicity. Imagine a marriage. A <a href='https://en.wikipedia.org/wiki/List_of_specialized_agencies_of_the_United_Nations' target='_blank'>UN agency</a> needs photos taken by somebody. They notice online that the <a href='https://en.wikipedia.org/wiki/List_of_specialized_agencies_of_the_United_Nations' target='_blank'>UN agency</a> includes a passion for photography. Since the supplier does not have an actual photography business, that variety of relationships and group action would represent C2C e-commerce.",
                },
                {
                  desc: "Another common situation is when individuals sell things online, on different websites, to others. Transactions come about online, and no party is a long-time business.",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: "quality-assurance",
      servicetitle: "Quality Assurance (QA)",
      servicecontent:
        "Quality Assurance (QA) is the process of planning, executing, and monitoring activities related to the quality of a product or service.",
      topPoints: [
        {
          id: "1",
          descriptions: [
            {
              desc: "The monitoring aspect of that discipline includes software quality assurance. It is the process or act of determining whether or not a company's quality requirements have been met.",
            },
            {
              desc: "Another meaning is that it is a component of quality management, which focuses on meeting quality standards. The quality assurance procedure has two components: internal management and customer confidence, as well as external <a href='http://vidhaninfotech.com' target='_black'>Vidhan Infotech</a> business credibility.",
            },
            {
              desc: "Through a set of established methods and procedures that carefully monitor multiple aspects of a product or service, QA aims to offer consistent outcomes. QA finds and corrects errors or deviations from organized standards or requirements through regular audits and other forms of assessments.",
            },
          ],
        },
        {
          id: "2",
          title: "What are the steps in the quality assurance procedure?",
          descriptions: [
            {
              desc: "QA refers to a set of procedures that a corporation should follow to verify that each product, service, or process meets quality standards.",
            },
          ],
        },
      ],
      QaSteps: [
        {
          title: "These are the steps :",
          customServices: [
            {
              id: "1",
              title: "Planning",
              desc: "The organization should plan and determine measures that will enable them to deliver high-quality products or services.",
            },
            {
              id: "2",
              title: "Testing",
              desc: " is a key stage in identifying any quality concerns with products and services, as well as the business process. Following the testing, the company must make changes to its products, services, and processes to satisfy the quality requirement.",
            },
            {
              id: "3",
              title: "Monitoring",
              desc: " In the QA process, this stage ensures that the organization meets its set goals and quality standards. The organization requires a dedicated crew to monitor and adapt the production processes as needed.",
            },
            {
              id: "4",
              title: "Implementation",
              desc: "The successful execution of actions that will improve the quality of company operations and goods.",
            },
          ],
        },
      ],
      howtoimprove: [
        {
          id: "1",
          List: [
            {
              id: "1",
              title: "How to Improve Quality Control Procedures",
              descriptions: [
                {
                  desc: "Quality control techniques ensure that manufactured goods fulfill predefined quality standards or customer specifications.",
                },
                {
                  desc: "Improving quality control is critical for every manufacturing or production company's long-term performance.",
                },
                {
                  desc: "Delivering high-quality products consistently using processes that meet Vidhan Infotech standards and searching for ways to improve quality control will help your company stay on top.",
                },
              ],
            },
          ],
        },
      ],
      improveQuality: [
        {
          id: "1",
          title: "The Benefits of Responsive Web Design",
          List: [
            {
              id: "1",
              title: "Step 1: Get everyone on board.",
              description: [
                {
                  desc: "Obtain buy-in and commitment from all employees to adhere to the organization's quality standards and processes. ",
                },
                {
                  desc: "Conduct onboarding for new hires and refresher training for long-term employees to ensure that everyone, not just leadership or the quality management team, is responsible for maintaining the quality of products and services.",
                },
              ],
            },
            {
              id: "2",
              title: "Step 2: Take the Initiative",
              description: [
                {
                  desc: "Regular quality inspections will reinforce quality control methods, and nonconformance detected during internal audits will be addressed immediately.",
                },
                {
                  desc: "During quality control procedure training, talk about ways to improve. To strengthen quality control operations, use production planning and control or production control approaches.",
                },
                {
                  desc: "Keep staff informed about new processes and encourage them to discuss best practices and make suggestions for how to increase quality even more.",
                },
              ],
            },
            {
              id: "3",
              title: "Step 3: Automate the record-keeping process.",
              description: [
                {
                  desc: "Quality control monitoring documentation is not only necessary for sustaining quality and safety requirements, but it also serves as a resource for ongoing quality improvement. Traditional paper-based recordkeeping systems can be inconvenient and time-consuming, prohibiting some auditors from documenting internal controls regularly. To reduce time and ensure that internal checks are consistently recorded, use automated digital recordkeeping.",
                },
              ],
            },
            {
              id: "4",
              title: "Step 4: Look for ways to improve.",
              description: [
                {
                  desc: "Conduct customer surveys, study incident reports, learn from past mistakes made by your company and others in the industry, keep up with industry best practices, and keep an eye out for new technology that can help you enhance procedures and the execution of quality standards in your company.",
                },
              ],
            },
            {
              id: "5",
              title: "Step 5: Make use of technology",
              description: [
                {
                  desc: "Save time and resources by utilizing modern technology such as sensors that automate quality parameter monitoring and mobile auditing applications to record and analyze data that paper audit forms cannot capture.",
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  const FoundServices = Services.find((element) => element.id === id);
  let SplittedHeading = "";
  if (FoundServices !== undefined) {
    SplittedHeading = FoundServices.servicetitle.split(" ");
  }

  return (
    <>
      <NavigationBar />
      <section className="view_services">
        <div className="container">
          {FoundServices !== undefined ? (
            <>
              <div className="common_heading service_heading text-center mt-3">
                {FoundServices !== undefined ? (
                  <h1>
                    {SplittedHeading.map((head) => (
                      <span>{head} </span>
                    ))}
                  </h1>
                ) : (
                  ""
                )}
                <p className="mt-4">{parse(FoundServices.servicecontent)}</p>
              </div>
              {FoundServices.app && (
                <div className="services_content">
                  {FoundServices.app?.map((data, i) => (
                    <div className="row mt-4" id={i}>
                      <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                        <div className="content_img">
                          <img
                            src={data.image}
                            alt={data.alt}
                            title={data.cap}
                            width="300px"
                            height="300px"
                          />
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                        <div className="content_text">
                          <h2>{parse(data.title)}</h2>
                          {data.description.map((desc, did) => (
                            <p id={did}>{parse(desc.desc)}</p>
                          ))}
                          {/* <Link to={{ pathname: "/home#HomeServices" }}>Let's Create For Your Brand <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill="" clip-rule="evenodd"><path d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z" /></svg></Link> */}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {FoundServices.lifeCycle && (
                <div className="lifecycle">
                  <div className="common_heading service_heading text-left mt-3">
                    <h2 className="mb-5 text-left">
                      Phases of the{" "}
                      <span>Mobile App Development Lifecycle</span>
                    </h2>
                  </div>

                  <div className="row">
                    {FoundServices.lifeCycle?.map((data, i) => (
                      <div
                        className="col-xl-4 col-lg-4 col-md-6 col-sm-12"
                        key={data.id}
                      >
                        <div className="cycle_box">
                          <div className="cycle_icon">{parse(data.id)}</div>
                          <div className="cycle_title">{parse(data.title)}</div>
                          <div className="cycle_desc">{parse(data.desc)}</div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {FoundServices.fiveForms &&
                FoundServices.fiveForms?.map((data, i) => (
                  <div className="benefits">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="benefit_title mt-0">
                          {data.title ? (
                            <h2 className="benefit_title mb-2">
                              {parse(data.title)}
                            </h2>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="common_content text-left">
                          {/* <p className='mt-0 mb-5'>{data.desc}</p> */}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="benefit_box">
                          <ul className="p-0">
                            {data.List?.map((data1, i) => (
                              <li className="benefit_desc" key={data1.id}>
                                {data1.title ? (
                                  <h3>
                                    <span>{data1.title}</span>
                                  </h3>
                                ) : (
                                  ""
                                )}
                                {data1.descriptions.map((data2, i) => (
                                  <p key={shortid.generate}>
                                    {parse(data2.desc)}
                                  </p>
                                ))}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              {FoundServices.developmentLifeCycle &&
                FoundServices.developmentLifeCycle?.map((data, i) => (
                  <>
                    <div className="common_heading service_heading text-center mt-3 mb-1">
                      <h2>{data.title}</h2>
                    </div>
                    <div className="common_content text-center">
                      <p className="mt-2">{data.desc}</p>
                    </div>
                  </>
                ))}

              {FoundServices.CustomWebServices && (
                <div className="lifecycle">
                  <div className="common_heading service_heading text-center mt-3">
                    <h2 className="mb-5">
                      <span>Custom Web Design </span>Services:
                    </h2>
                  </div>
                  <div className="row">
                    {FoundServices.CustomWebServices?.map((data, i) =>
                      data.customServices.map((data1, i1) => (
                        <div
                          className="col-xl-4 col-lg-4 col-md-6 col-sm-12"
                          key={data1.id}
                        >
                          <div className="cycle_box">
                            <div className="cycle_icon">{data1.id}</div>
                            <div className="cycle_title">{data1.title}</div>
                            <div className="cycle_desc">
                              {parse(data1.desc)}
                            </div>
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </div>
              )}
              {FoundServices.Benefits &&
                FoundServices.Benefits?.map((data, i) => (
                  <div className="benefits">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="benefit_title mb-2">
                          <h2 className="benefit_title">{parse(data.title)}</h2>
                        </div>
                        <div className="common_content text-left">
                          {data.desc ? (
                            <p className="mt-0 mb-5">{data.desc}</p>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="benefit_box">
                          <ul>
                            {data.List?.map((data1, i) => (
                              <li
                                className="benefit_desc"
                                key={shortid.generate}
                              >
                                <img
                                  src={Star}
                                  alt={
                                    "Benefits of Application Development in Green Icon"
                                  }
                                  title={
                                    "Benefits of Application Development in Green Icon"
                                  }
                                />
                                {parse(data1.desc)}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              {FoundServices.SDLC &&
                FoundServices.SDLC?.map((data, i) => (
                  <div className="lifecycle">
                    <div className="common_heading service_heading text-center mt-3">
                      <h2 className="mb-5">{data.title}</h2>
                    </div>
                    <div className="row">
                      {data.customServices.map((data1, i1) => (
                        <div
                          className="col-xl-4 col-lg-4 col-md-6 col-sm-12"
                          key={data1.id}
                        >
                          <div className="cycle_box">
                            <div className="cycle_icon">{data1.id}</div>
                            <div className="cycle_title">{data1.title}</div>
                            <div className="cycle_desc">{data1.desc}</div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              {FoundServices.developmentVidhan &&
                FoundServices.developmentVidhan?.map((data, i) => (
                  <>
                    <div className="common_heading service_heading text-center mt-3 mb-1">
                      <h2>{parse(data.title)}</h2>
                    </div>
                    <div className="common_content text-center">
                      <p className="mt-2">{parse(data.desc)}</p>
                    </div>
                  </>
                ))}

              {FoundServices.titledPoint &&
                FoundServices.titledPoint?.map((data, i) => (
                  <div className="benefits">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="benefit_title m-0">
                          {data.title ? (
                            <h2 className="benefit_title">{data.title}</h2>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="common_content text-left">
                          {data.desc ? (
                            <p className="mt-0 mb-5">{data.desc}</p>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="benefit_box">
                          <ul className="p-0">
                            {data.List?.map((data1, i) => (
                              <li
                                className="benefit_desc"
                                key={shortid.generate}
                              >
                                {data1.title ? (
                                  <h3 className="benefit_title">
                                    {data1.title}
                                  </h3>
                                ) : (
                                  ""
                                )}
                                {parse(data1.desc)}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

              {FoundServices.erpappserv &&
                FoundServices.erpappserv?.map((data, i) => (
                  <div className="benefits">
                    <div className="row">
                      <div className="col-md-12">
                        {data.title ? (
                          <div className="benefit_title mb-2">
                            <h2 className="benefit_title">{data.title}</h2>
                          </div>
                        ) : (
                          ""
                        )}
                        {data.tit ? (
                          <div className="benefit_title mb-2">
                            <h3 className="benefit_title">{data.tit}</h3>
                          </div>
                        ) : (
                          ""
                        )}
                        {data.desc ? (
                          <div className="common_content text-left">
                            <p className="mt-0 mb-5">{data.desc}</p>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="col-md-12">
                        <div className="benefit_box">
                          <ul className="p-0">
                            {data.List?.map((data1, i) => (
                              <li
                                className="benefit_desc"
                                key={shortid.generate}
                              >
                                {data1.title ? (
                                  <h3 className="benefit_title">
                                    {data1.title}
                                  </h3>
                                ) : (
                                  ""
                                )}
                                {data1.title1 ? (
                                  <h4 className="benefit_title">
                                    {data1.title1}
                                  </h4>
                                ) : (
                                  ""
                                )}
                                {data1.descriptions?.map((data2, i) => (
                                  <p key={shortid.generate}>
                                    {parse(data2.desc)}
                                  </p>
                                ))}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              {FoundServices.topPoints && (
                <div className="benefits">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="benefit_box">
                        <ul className="p-0">
                          {FoundServices.topPoints?.map((data, i) => (
                            <li className="benefit_desc" key={data.id}>
                              {data.title ? (
                                <h2 className="benefit_title">
                                  {parse(data.title)}
                                </h2>
                              ) : (
                                ""
                              )}
                              {data.descriptions?.map((data2, i) => (
                                <p key={shortid.generate}>
                                  {parse(data2.desc)}
                                </p>
                              ))}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {FoundServices.QaSteps && (
                <div className="lifecycle">
                  <div className="common_heading service_heading text-center mt-3">
                    <h3
                      className="benefit_title mt-0 mb-5"
                      style={{ textAlign: "left" }}
                    >
                      {" "}
                      These are the steps :
                    </h3>
                  </div>
                  <div className="row">
                    {FoundServices.QaSteps?.map((data, i) =>
                      data.customServices.map((data1, i1) => (
                        <div
                          className="col-xl-6 col-lg-6 col-md-6 col-sm-12"
                          key={data1.id}
                        >
                          <div className="cycle_box">
                            <div className="cycle_icon">{data1.id}</div>
                            <div className="cycle_title">{data1.title}</div>
                            <div className="cycle_desc">
                              {parse(data1.desc)}
                            </div>
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </div>
              )}
              {FoundServices.howtoimprove &&
                FoundServices.howtoimprove?.map((data, i) => (
                  <div className="benefits">
                    <div className="row">
                      <div className="col-md-12">
                        {data.title ? (
                          <div className="benefit_title">{data.title}</div>
                        ) : (
                          ""
                        )}
                        {data.desc ? (
                          <div className="common_content text-left">
                            <p className="mt-0 mb-5">{data.desc}</p>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="col-md-12">
                        <div className="benefit_box">
                          <ul className="p-0">
                            {data.List?.map((data1, i) => (
                              <li
                                className="benefit_desc"
                                key={shortid.generate}
                              >
                                {data1.title ? (
                                  <h2 className="benefit_title">
                                    {data1.title}
                                  </h2>
                                ) : (
                                  ""
                                )}
                                {data1.descriptions.map((data2, i) => (
                                  <p key={shortid.generate}>
                                    {parse(data2.desc)}
                                  </p>
                                ))}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              {FoundServices.improveQuality &&
                FoundServices.improveQuality?.map((data, i) => (
                  <div className="benefits">
                    <div className="row">
                      <div className="col-md-12">
                        {data.title ? (
                          <div className="benefit_title">
                            <h2 className="benefit_title">{data.title}</h2>
                          </div>
                        ) : (
                          ""
                        )}
                        {data.desc ? (
                          <div className="common_content text-left">
                            <p className="mt-0 mb-5">{data.desc}</p>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="col-md-12">
                        <div className="benefit_box">
                          <ul className="p-0">
                            {data.List?.map((data1, i) => (
                              <li
                                className="benefit_desc"
                                key={shortid.generate}
                              >
                                <h3 className="benefit_title">{data1.title}</h3>

                                {data1.description?.map((data2, i) => (
                                  <span
                                    key={shortid.generate}
                                    className="qa_desc"
                                  >
                                    {data2.desc}
                                  </span>
                                ))}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </>
          ) : (
            <>
              <p className="services_error">No Service Available</p>
            </>
          )}
        </div>
      </section>
      <NewFooter />
    </>
  );
}
export default React.memo(ViewServices);
